import { useEffect, useCallback } from 'react';
import {
  OVERVIEW_PATH,
  ALL_INTERCEPTIONS_PATH,
  RADIO_NETWORKS_PATH_BASE,
  FACTIONS_PATH_BASE,
  SOURCES_PATH_BASE,
  ACCESS_GROUPS_PATH_BASE,
  CATEGORIES_PATH_BASE,
  UNITS_PATH_BASE,
  ALLOWED_PAGES,
  ALL_TRANSCRIPTS_QUERY_PARAMS_KEY,
  NETWORKS_QUERY_PARAMS_KEY,
  TranscriptQueryParam,
  NetworkQueryParam,
  SourceQueryParam,
  SOURCES_FILTERS_STORAGE_KEY,
} from '@/shared/constants';
import { useNavigate, useAppSelector, useAppDispatch } from '@/shared/hooks';
import { hotkeysHelperService } from '@/shared/services';
import { selectCurrentUserRole, setNavigationDrawer } from '@/shared/slices';
import { forceExitFullscreen } from '@/shared/utils';
import { useNavigateWithStorageParams } from './useNavigateWithStorageParams';

let isGoKeyPressed = false;

const SEQUENCE_MAX_INTERVAL = 2000;
const GOTO_KEYCODE = 'KeyG';

enum PageKeyCode {
  Overview = 'KeyO',
  AllInterceptions = 'KeyI',
  RadioNetworks = 'KeyN',
  Sources = 'KeyS',
  Factions = 'KeyF',
  Categories = 'KeyC',
  AccessGroups = 'KeyA',
  Units = 'KeyU',
}

const useNavigationHotkeys = () => {
  const role = useAppSelector(selectCurrentUserRole);
  const navigate = useNavigate();
  const navigateToAllInterceptions = useNavigateWithStorageParams(
    TranscriptQueryParam,
    ALL_TRANSCRIPTS_QUERY_PARAMS_KEY
  );
  const navigateToRadioNetworks = useNavigateWithStorageParams(NetworkQueryParam, NETWORKS_QUERY_PARAMS_KEY);
  const navigateToSources = useNavigateWithStorageParams(SourceQueryParam, SOURCES_FILTERS_STORAGE_KEY);
  const dispatch = useAppDispatch();

  const allowedPaths = ALLOWED_PAGES[role];

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (hotkeysHelperService.getIsInputOrTextarea(e)) return;
      const keyCode = e.code;

      if (keyCode === GOTO_KEYCODE) {
        isGoKeyPressed = true;
        setTimeout(() => {
          isGoKeyPressed = false;
        }, SEQUENCE_MAX_INTERVAL);
      }

      if (!isGoKeyPressed || !Object.values<string>(PageKeyCode).includes(keyCode)) return;

      if (keyCode === PageKeyCode.Overview && allowedPaths.includes(OVERVIEW_PATH)) {
        navigate(OVERVIEW_PATH);
      }
      if (keyCode === PageKeyCode.AllInterceptions && allowedPaths.includes(ALL_INTERCEPTIONS_PATH)) {
        navigateToAllInterceptions(ALL_INTERCEPTIONS_PATH);
      }
      if (keyCode === PageKeyCode.RadioNetworks && allowedPaths.includes(RADIO_NETWORKS_PATH_BASE)) {
        navigateToRadioNetworks(RADIO_NETWORKS_PATH_BASE);
      }
      if (keyCode === PageKeyCode.Sources && allowedPaths.includes(SOURCES_PATH_BASE)) {
        navigateToSources(SOURCES_PATH_BASE);
      }
      if (keyCode === PageKeyCode.Factions && allowedPaths.includes(FACTIONS_PATH_BASE)) {
        navigate(FACTIONS_PATH_BASE);
      }
      if (keyCode === PageKeyCode.Categories && allowedPaths.includes(CATEGORIES_PATH_BASE)) {
        navigate(CATEGORIES_PATH_BASE);
      }
      if (keyCode === PageKeyCode.AccessGroups && allowedPaths.includes(ACCESS_GROUPS_PATH_BASE)) {
        navigate(ACCESS_GROUPS_PATH_BASE);
      }
      if (keyCode === PageKeyCode.Units && allowedPaths.includes(UNITS_PATH_BASE)) {
        navigate(UNITS_PATH_BASE);
      }

      // close navigation drawer if it is opened
      dispatch(setNavigationDrawer(null));
      forceExitFullscreen();
    },
    [navigateToAllInterceptions, navigateToRadioNetworks, navigateToSources, dispatch]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);
};

export default useNavigationHotkeys;
