import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getLocations } from '@/entities/location/api';
import { Paginated } from '@/shared/api';
import { LOCATIONS_QUERY_KEY } from '@/shared/constants';
import { Location } from '@/shared/types';

type Params = {
  search: string;
  type?: 'state';
  options?: Omit<UseQueryOptions<unknown, unknown, Paginated<Location>, (string | undefined)[]>, 'queryKey'>;
};

const useGetLocations = ({ search, type, options = {} }: Params) => {
  const {
    data,
    isFetching: isLocationsFetching,
    isLoading: isLocationsLoading,
  } = useQuery({
    ...options,
    queryKey: [LOCATIONS_QUERY_KEY, search, type],
    queryFn: () => getLocations(search, type),
  });

  return {
    locations: data?.models || [],
    isLocationsFetching,
    isLocationsLoading,
  };
};

export default useGetLocations;
