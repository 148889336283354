import { ArrowIcon, ShiftIcon } from '@/shared/assets';
import { Tag } from '@/shared/components/ui/Tag';
import HotkeyRow from '../HotkeyRow';
import HotkeysLayout from '../HotkeysLayout';
import styles from '../styles.module.css';
import {
  BLACK_WHITE_TILES,
  CHANGE_BINDING_TYPE,
  DISPLAY_BATTLE_LINE,
  DISPLAY_MAP_PANEL,
  ELEVATION_TILES,
  FULL_SCREEN_MODE,
  GOOGLE_TILES,
  MOVE,
  OPEN_STREET_TILES,
  ROTATE,
  TILT,
  ZOOM_IN_OUT,
} from './constants';

const MapHotkeys = () => {
  return (
    <HotkeysLayout
      firstColumn={
        <>
          <HotkeyRow label={DISPLAY_MAP_PANEL}>
            <Tag>M</Tag>
          </HotkeyRow>
          <HotkeyRow label={FULL_SCREEN_MODE}>
            <Tag>F</Tag>
          </HotkeyRow>
          <HotkeyRow label={DISPLAY_BATTLE_LINE}>
            <Tag>L</Tag>
          </HotkeyRow>
          <HotkeyRow label={ELEVATION_TILES}>
            <Tag>H</Tag>
          </HotkeyRow>
          <HotkeyRow label={GOOGLE_TILES}>
            <Tag>S</Tag>
          </HotkeyRow>
          <HotkeyRow label={OPEN_STREET_TILES}>
            <Tag>O</Tag>
          </HotkeyRow>
        </>
      }
      secondColumn={
        <>
          <HotkeyRow label={BLACK_WHITE_TILES}>
            <Tag>B</Tag>
          </HotkeyRow>
          <HotkeyRow label={CHANGE_BINDING_TYPE}>
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>P</Tag>
          </HotkeyRow>
          <HotkeyRow label={ZOOM_IN_OUT}>
            <Tag>+</Tag>/<Tag>–</Tag>
          </HotkeyRow>
          <HotkeyRow label={ROTATE}>
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowLeft} />
            </Tag>
            /
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowRight} />
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={TILT}>
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowTop} />
            </Tag>
            /
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowBottom} />
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={MOVE}>
            <Tag>
              <ArrowIcon className={styles.arrowLeft} />
            </Tag>
            /
            <Tag>
              <ArrowIcon className={styles.arrowRight} />
            </Tag>
            /
            <Tag>
              <ArrowIcon className={styles.arrowTop} />
            </Tag>
            /
            <Tag>
              <ArrowIcon className={styles.arrowBottom} />
            </Tag>
          </HotkeyRow>
        </>
      }
    />
  );
};

export default MapHotkeys;
