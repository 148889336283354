import upperFirst from 'lodash/upperFirst';

type ExtendByExtraParams = {
  pageParam?: number;
  queryParams: string;
  extra: Record<string, string | string[] | null | undefined>;
};

export class QueryParamsService {
  setQueryParamsArrayParameter(params: URLSearchParams, paramName: string, idsArray?: string[]) {
    idsArray?.forEach((id) => {
      params.append(paramName, id);
    });
  }

  /**
   * @deprecated Please use extendByExtra instead
   */
  getQueryParamsWithFoffset(pageNumber: number, queryParams: string, foffset: string | null) {
    if (pageNumber === 1 || !foffset) {
      return queryParams;
    }
    return queryParams.length > 0 ? queryParams.concat(`&foffset=${foffset}`) : `foffset=${foffset}`;
  }

  extendByExtra({ pageParam, queryParams, extra }: ExtendByExtraParams, { checkPagination = true } = {}) {
    const extraParams = new URLSearchParams();

    Object.entries(extra).forEach(([key, value]) => {
      if (!value) return;

      if (Array.isArray(value)) {
        this.setQueryParamsArrayParameter(extraParams, `${key}[]`, value);
      } else {
        extraParams.append(key, value);
      }
    });

    if ((checkPagination && pageParam === 1) || extraParams.toString().length === 0) {
      return queryParams;
    }

    return queryParams.concat(queryParams.length > 0 ? `&${extraParams.toString()}` : extraParams.toString());
  }

  convertArrayToQueryParam<T>(value: T[]) {
    if (!Array.isArray(value)) return value;
    return value.join(',');
  }

  convertQueryParamToArray(value: string) {
    return value ? value.split(',') : [];
  }

  getQueryStringFromStorage(storage: unknown, queryParam?: Record<string, string>) {
    let resultString = '';

    if (typeof storage === 'object' && storage !== null) {
      Object.entries(storage).forEach(([storageKey, value]) => {
        const queryKey = queryParam?.[upperFirst(storageKey)];
        let keyValue = '';
        if (!queryKey || !value) return;
        if (typeof value === 'object' && 'value' in value && value.value.length) {
          keyValue = `${queryKey}=${this.convertArrayToQueryParam(value.value)}`;
        } else if (value.length) {
          keyValue = `${queryKey}=${this.convertArrayToQueryParam(value)}`;
        }
        if (!keyValue) return;
        resultString = resultString ? `${resultString}&${keyValue}` : `${resultString}${keyValue}`;
      });
    }
    return resultString;
  }

  getUpdatedFilterQueryParam(queryParam: Record<string, string>, storage: unknown, initParams?: URLSearchParams) {
    return () => {
      const params = initParams ?? new URLSearchParams();

      if (typeof storage === 'object' && storage !== null) {
        Object.entries(storage).forEach(([storageKey, value]) => {
          const queryKey = queryParam[upperFirst(storageKey)];
          if (!queryKey || !value) return;
          if (typeof value === 'object' && 'value' in value && value.value.length) {
            params.set(queryKey, this.convertArrayToQueryParam(value.value));
          } else if (value.length) {
            params.set(queryKey, this.convertArrayToQueryParam(value));
          }
        });
      }
      return params;
    };
  }
}

export const queryParamsService = new QueryParamsService();
