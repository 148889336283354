export enum ItemKey {
  Navigation = 'navigation',
  Text = 'text',
  Map = 'map',
  Audio = 'audio',
}

export enum ItemLabel {
  Navigation = 'Навігація',
  Text = 'Текст',
  Map = 'Мапа',
  Audio = 'Аудіо',
}
