import { useState } from 'react';
import { getFreeUsers } from '@/entities/unit/api';
import { UnitUser } from '@/entities/unit/types';
import { UNIT_FREE_USERS_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery, UseInfiniteListQueryOptions, useUpdateFoffset } from '@/shared/hooks';

interface Params {
  options?: Omit<UseInfiniteListQueryOptions<UnitUser>, 'queryKey' | 'initialPageParam'>;
}

const useGetFreeUsers = ({ options }: Params = {}) => {
  const [foffset, setFoffset] = useState<string | null>(null);

  const {
    data,
    list: freeUsersList,
    getListRef: getFreeUsersRef,
    isLoading: isFreeUsersLoading,
    isFetching: isFreeUsersFetching,
    refetch: refetchFreeUsers,
  } = useInfiniteListQuery({
    ...options,
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [UNIT_FREE_USERS_QUERY_KEY],
    queryFn: ({ pageParam }) => {
      return getFreeUsers({
        queryParams: {
          page: pageParam as number,
          foffset: pageParam !== 1 ? foffset : null,
        },
      });
    },
    initialPageParam: 1,
  });

  useUpdateFoffset({ data, setFoffset });

  return {
    freeUsersList,
    getFreeUsersRef,
    isFreeUsersLoading,
    isFreeUsersFetching,
    refetchFreeUsers,
  };
};

export default useGetFreeUsers;
