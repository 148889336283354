import { LabeledValue } from 'antd/es/select';
import { Location } from '@/shared/types';
import { CAPITAL_CITY_DISTRICTS, LOCATION_TYPES } from '../constants';
import capitalizeWord from './capitalizeWord';
import getLocationNameWithDistrict from './getLocationNameWithDistrict';

const getLocationsOptionsGroupedByRegion = (locations: Location[]) => {
  if (!locations?.length) {
    return [];
  }

  const locationsByOblastDictionary = locations.reduce(
    (acc, { name, state, district, id, type, coordinates }) => {
      let locationState = state;
      if (type === LOCATION_TYPES.capital) {
        locationState = CAPITAL_CITY_DISTRICTS[name.toLowerCase()];
      }
      if (!locationState) {
        return acc;
      }

      const isOblastExists = acc?.[capitalizeWord(locationState)];
      const locationOption = {
        label: getLocationNameWithDistrict(name, district, type),
        value: id,
        locationName: capitalizeWord(name),
        locationCoordinates: coordinates,
      };
      if (!isOblastExists) {
        acc[capitalizeWord(locationState)] = [locationOption];
      }
      if (isOblastExists) {
        acc[capitalizeWord(locationState)].push(locationOption);
      }

      return acc;
    },
    {} as Record<string, LabeledValue[]>
  );

  return Object.entries(locationsByOblastDictionary).map(([key, value]) => ({
    label: key,
    options: value,
  }));
};

export default getLocationsOptionsGroupedByRegion;
