import { useFirstMountState } from '@reactuses/core';
import { useGetFrequencies } from '@/entities/frequency';
import { SubscriptionType } from '@/entities/subscription';
import { Frequency } from '@/shared/types';
import useSubscriptionState from './useSubscriptionState';

const useGetSelectedFrequencies = () => {
  const { getSubscriptionValues } = useSubscriptionState();
  const subscribedFrequencyIds = getSubscriptionValues(SubscriptionType.Frequency) as Frequency['id'][];
  const isFirstMount = useFirstMountState();

  const { isLoading } = useGetFrequencies({
    ids: subscribedFrequencyIds,
    isEnabled: isFirstMount,
  });

  return {
    selectedFrequencies: subscribedFrequencyIds,
    isSelectedFrequenciesLoading: isLoading,
  };
};

export default useGetSelectedFrequencies;
