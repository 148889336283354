import { MenuItemType } from 'antd/es/menu/interface';
import { EditOutlined } from '@ant-design/icons';
import { RoleName } from '@/shared/constants';
import { useIsUserRole } from '@/shared/hooks';

const ADMIN_ROLES = [RoleName.ADMIN];
enum ItemKey {
  Edit = 'edit',
}
enum ItemLabel {
  Edit = 'Редагувати джерело',
}

interface Params {
  onEditClick: VoidFunction;
}

const useActionItems = ({ onEditClick }: Params) => {
  const isAdmin = useIsUserRole({ memoRoles: ADMIN_ROLES });

  const actionItems: MenuItemType[] = isAdmin
    ? [
        {
          key: ItemKey.Edit,
          label: ItemLabel.Edit,
          icon: <EditOutlined />,
          onClick: onEditClick,
        },
      ]
    : [];

  return actionItems;
};

export default useActionItems;
