import pipe from 'lodash/fp/pipe';

type StopPropagationParams = {
  [key: string]: any;
  stopPropagation?: VoidFunction;
};

export const stopPropagation = (params: StopPropagationParams) => {
  params.stopPropagation?.();
  return params;
};

const withStopPropagation = (callback?: (params: any) => void) => {
  if (!callback) return stopPropagation;

  return pipe(stopPropagation, callback);
};

export default withStopPropagation;
