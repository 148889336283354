import { useMutation, MutateOptions } from '@tanstack/react-query';
import {
  deactivateFoxFrequencies as deactivateFoxFrequenciesApi,
  DeactivateFoxFrequenciesParams,
} from '@/entities/source/@fox/api';
import { UpdateFoxFrequenciesStatus } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY } from '@/shared/constants';

const useDeactivateFoxFrequencies = (
  options: MutateOptions<ResponseData<UpdateFoxFrequenciesStatus>, unknown, DeactivateFoxFrequenciesParams> = {}
) => {
  const { mutate: deactivateFoxFrequencies, isPending: isFoxFrequenciesDeactivating } = useMutation({
    ...options,
    mutationKey: [DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY],
    mutationFn: deactivateFoxFrequenciesApi,
  });

  return {
    deactivateFoxFrequencies,
    isFoxFrequenciesDeactivating,
  };
};

export default useDeactivateFoxFrequencies;
