import { z } from 'zod';

export const sourceTypeSchema = z.enum(['af', 'bilka', 'fox', 'sailor-moon', 'signal', 'vuho', 'whatsapp']);

export const foxStatusSchema = z.enum(['offline', 'online', 'reboot', 'not_active']);

export const foxDeviceStatusSchema = z.enum(['sdr_stopped', 'sdr_shutdown', 'sdr_online']);

export const foxFrequencyRangeSchema = z.object({
  from: z.number().optional(),
  to: z.number().optional(),
});

export const baseSourceSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: sourceTypeSchema,
  groupId: z.string(),
  firstActiveAt: z.number().nullish(),
  lastActiveAt: z.number().nullish(),
  area: z.array(z.string()),
  externalId: z.string(),
  unitId: z.string().nullish(),
});
