import { CommandIcon } from '@/shared/assets';
import { Tag } from '@/shared/components/ui/Tag';
import styles from '../styles.module.css';

const CtrlTag = () => {
  const isMac = /Mac/i.test(navigator.userAgent);

  return <Tag className={styles.hasLongText}>{isMac ? <CommandIcon /> : 'Ctrl'}</Tag>;
};

export default CtrlTag;
