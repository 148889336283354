import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { ViewportService, SourceService, LayerService, MarkerService, ClusterService } from '@/entities/map/services';
import { MapContextState, MapContextProviderProps } from './types';

const MapContext = createContext<MapContextState | null>(null);

export const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error('useMapContext should be used inside MapContextProvider');
  }
  return context;
};

const MapContextProvider = ({ map, isHotkeysEnabled, children }: PropsWithChildren<MapContextProviderProps>) => {
  const value: MapContextState | null = useMemo(() => {
    return map
      ? {
          map,
          isHotkeysEnabled,
          viewportService: new ViewportService(map),
          sourceService: new SourceService(map),
          layerService: new LayerService(map),
          markerService: new MarkerService(map),
          clusterService: new ClusterService(map),
        }
      : null;
  }, [map, isHotkeysEnabled]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export default MapContextProvider;
