import { ArrowIcon, ShiftIcon } from '@/shared/assets';
import { Tag } from '@/shared/components/ui/Tag';
import HotkeyRow from '../HotkeyRow';
import HotkeysLayout from '../HotkeysLayout';
import styles from '../styles.module.css';
import { AUTOPLAY, FORWARD_BACKWARD, NEXT_PREVIOUS, PLAY_STOP, SPACE, SPEED_UP_SLOW_DOWN } from './constants';

const AudioHotkeys = () => {
  return (
    <HotkeysLayout
      firstColumn={
        <>
          <HotkeyRow label={PLAY_STOP}>
            <Tag className={styles.hasLongText}>{SPACE}</Tag>
          </HotkeyRow>
          <HotkeyRow label={AUTOPLAY}>
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag className={styles.hasLongText}>{SPACE}</Tag>
          </HotkeyRow>
          <HotkeyRow label={NEXT_PREVIOUS}>
            <Tag>
              <ArrowIcon className={styles.arrowTop} />
            </Tag>
            /
            <Tag>
              <ArrowIcon className={styles.arrowBottom} />
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={FORWARD_BACKWARD}>
            <Tag>
              <ArrowIcon className={styles.arrowRight} />
            </Tag>
            /
            <Tag>
              <ArrowIcon className={styles.arrowLeft} />
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={SPEED_UP_SLOW_DOWN}>
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowTop} />
            </Tag>
            /
            <Tag>
              <ShiftIcon className={styles.shift} />
            </Tag>
            <Tag>
              <ArrowIcon className={styles.arrowBottom} />
            </Tag>
          </HotkeyRow>
        </>
      }
    />
  );
};

export default AudioHotkeys;
