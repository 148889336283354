import { useMutation, MutateOptions } from '@tanstack/react-query';
import { updateFoxFrequency as updateFoxFrequencyApi, UpdateFoxFrequencyParams } from '@/entities/source/@fox/api';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { UPDATE_FOX_FREQUENCY_MUTATION_KEY } from '@/shared/constants';

const useUpdateFoxFrequency = (
  options: MutateOptions<ResponseData<FoxFrequency>, unknown, UpdateFoxFrequencyParams> = {}
) => {
  const { mutate: updateFoxFrequency, isPending: isFoxFrequencyUpdating } = useMutation({
    ...options,
    mutationKey: [UPDATE_FOX_FREQUENCY_MUTATION_KEY],
    mutationFn: updateFoxFrequencyApi,
  });

  return {
    updateFoxFrequency,
    isFoxFrequencyUpdating,
  };
};

export default useUpdateFoxFrequency;
