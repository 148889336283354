import { Typography } from 'antd';
import { EnterIcon } from '@/shared/assets';
import { Tag } from '@/shared/components/ui/Tag';
import CtrlTag from '../CtrlTag';
import HotkeyRow from '../HotkeyRow';
import HotkeysLayout from '../HotkeysLayout';
import styles from '../styles.module.css';
import { APPLY_CHANGES, CANCEL_CHANGES, SIMPLE_INPUT_FIELD, TEXTAREA_FIELDS } from './constants';

const TextHotkeys = () => {
  return (
    <HotkeysLayout
      firstColumn={
        <>
          <Typography.Text strong>{SIMPLE_INPUT_FIELD}</Typography.Text>
          <HotkeyRow label={APPLY_CHANGES}>
            <Tag className={styles.hasLongText}>
              <EnterIcon />
              Enter
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={CANCEL_CHANGES}>
            <Tag className={styles.hasLongText}>Esc</Tag>
          </HotkeyRow>
        </>
      }
      secondColumn={
        <>
          <Typography.Text strong>{TEXTAREA_FIELDS}</Typography.Text>
          <HotkeyRow label={APPLY_CHANGES}>
            <CtrlTag />
            <Tag className={styles.hasLongText}>
              <EnterIcon />
              Enter
            </Tag>
          </HotkeyRow>
          <HotkeyRow label={CANCEL_CHANGES}>
            <Tag className={styles.hasLongText}>Esc</Tag>
          </HotkeyRow>
        </>
      }
    />
  );
};

export default TextHotkeys;
