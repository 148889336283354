import { useTranscriptQueryParams } from '@/entities/transcript/hooks';
import { MobileView, MobileViewQueryParam, NetworkQueryParam } from '@/shared/constants';
import { useIsMobile, useNavigate } from '@/shared/hooks';
import { routingService } from '@/shared/services';

const useNetworkLink = (networkId: string) => {
  const { group } = useTranscriptQueryParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const searchParams = new URLSearchParams();

  if (isMobile) {
    searchParams.set(MobileViewQueryParam.Mobile, MobileView.SecondaryScreen);
  }
  if (group) {
    searchParams.set(NetworkQueryParam.Group, group);
  }

  const path = routingService.getRadioNetworkUrl({ radioNetworkId: networkId });
  const queryString = searchParams.toString();

  const networkLinkHref = queryString ? `${path}?${queryString}` : path;

  const handleNetworkClick = () => {
    navigate({
      pathname: path,
      options: {
        additionalQueryParams: queryString,
      },
    });
  };

  return {
    networkLinkHref,
    handleNetworkClick,
  };
};

export default useNetworkLink;
