import { MenuItemType } from 'antd/es/menu/interface';
import { DeleteOutlined } from '@ant-design/icons';
import {
  useDeleteFoxFrequency,
  useDeleteFoxFrequencyFromCache,
  useFoxFrequenciesQueryParams,
} from '@/entities/source/@fox/components/ScanningTab/hooks';
import { FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';
import { useParams, useConfirmModal } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { UseFoxFrequencyActionsParams } from '../types';

export enum ItemLabel {
  Delete = 'Видалити частоту',
}
export enum ItemKey {
  Delete = 'delete',
}

const DELETE_MODAL_TITLE = 'Видалити частоту?';
const DELETE_MODAL_CONTENT = `Вибрану частоту буде видалено назавжди.
Отримані перехоплення залишаться в системі.`;

interface Params extends Pick<UseFoxFrequencyActionsParams, 'foxFrequencyId' | 'onDelete'> {}

const useDangerItems = ({ foxFrequencyId, onDelete }: Params) => {
  const { sourceId } = useParams<SourceRouteParams>();
  const queryParams = useFoxFrequenciesQueryParams();
  const deleteFoxFrequencyFromCache = useDeleteFoxFrequencyFromCache([
    FOX_FREQUENCIES_QUERY_KEY,
    sourceId,
    queryParams,
  ]);
  const { deleteFoxFrequency } = useDeleteFoxFrequency({
    onSuccess: () => {
      onDelete?.();
      deleteFoxFrequencyFromCache(foxFrequencyId);
    },
  });

  const handleDeleteFoxFrequency = useConfirmModal({
    title: DELETE_MODAL_TITLE,
    content: DELETE_MODAL_CONTENT,
    onOk: async () => {
      if (!sourceId) return;

      return deleteFoxFrequency({ sourceId, foxFrequencyId });
    },
  });

  const dangerItems: MenuItemType[] = [
    {
      key: ItemKey.Delete,
      label: ItemLabel.Delete,
      icon: <DeleteOutlined />,
      onClick: handleDeleteFoxFrequency,
    },
  ];

  return dangerItems;
};

export default useDangerItems;
