import { MenuItemType } from 'antd/es/menu/interface';
import { DeleteOutlined } from '@ant-design/icons';
import { useGetFoxKeys, useNavigateToFoxKey } from '@/entities/source/@fox/components/KeysTab/hooks';
import { FoxKey } from '@/entities/source/@fox/types';
import { useConfirmModal, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useDeleteFoxKey from './useDeleteFoxKey';
import useDeleteRecordFromCache from './useDeleteRecordFromCache';

const DELETE_MODAL_TITLE = 'Видалити ключ?';
const DELETE_MODAL_CONTENT = 'Вибраний запис буде видалено з цього списку і джерела назавжди.';
enum ItemLabel {
  Delete = 'Видалити ключ',
}
enum ItemKey {
  Delete = 'delete',
}

interface Params {
  keyId: FoxKey['id'];
  onDelete?: VoidFunction;
}

const useFoxKeyActions = ({ keyId, onDelete }: Params) => {
  const { sourceId, tabEntityId } = useParams<SourceRouteParams>();
  const navigateToFoxKey = useNavigateToFoxKey();
  const { foxKeysList } = useGetFoxKeys({ options: { enabled: false } });

  const { setDeleteRecordIdFromCache } = useDeleteRecordFromCache();

  const { deleteFoxKey, isFoxKeyDeleting } = useDeleteFoxKey({
    onSuccess: () => {
      onDelete?.();

      if (tabEntityId === keyId) {
        navigateToFoxKey({
          routeParams: {
            sourceId,
            tabEntityId: foxKeysList.find((foxKey) => foxKey.id !== tabEntityId)?.id,
          },
          options: {
            withAllQueryParams: true,
          },
        });
        setDeleteRecordIdFromCache(keyId);
      }
    },
  });

  const handleDeleteKey = useConfirmModal({
    title: DELETE_MODAL_TITLE,
    content: DELETE_MODAL_CONTENT,
    onOk: async () => {
      if (!sourceId) return;

      deleteFoxKey({ sourceId, foxKeyId: keyId });
    },
  });

  const dangerItems: MenuItemType[] = [
    {
      key: ItemKey.Delete,
      label: ItemLabel.Delete,
      icon: <DeleteOutlined />,
      onClick: handleDeleteKey,
    },
  ];

  return {
    dangerItems,
    isLoading: isFoxKeyDeleting,
  };
};

export default useFoxKeyActions;
