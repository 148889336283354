import { useMemo, useRef } from 'react';
import { useInitializeMap, useMapFullScreen, useMapVisibility } from './hooks';
import { MapContainerProps } from './types';

interface Params extends Pick<MapContainerProps, 'tileSource' | 'mapOptions' | 'isMapAvailable'> {}

const useMapContainer = ({ tileSource, mapOptions, isMapAvailable = true }: Params) => {
  const providerWrapperRef = useRef<HTMLDivElement>(null);
  const { mapContainerRefCallback, map, isMapLoaded } = useInitializeMap(mapOptions, tileSource);
  const isMapVisible = useMapVisibility({ providerWrapperRef });
  const isMapInteractive = mapOptions.interactive ?? true;

  const isHotkeysEnabled = useMemo(() => {
    if (!isMapInteractive || !isMapAvailable) return false;
    return isMapVisible;
  }, [isMapInteractive, isMapAvailable, isMapVisible]);

  const { isFullScreenEnabled } = useMapFullScreen({ isHotkeysEnabled });

  return {
    providerWrapperRef,
    mapContainerRefCallback,
    map,
    isMapLoaded,
    isHotkeysEnabled,
    isFullScreenEnabled,
  };
};

export default useMapContainer;
