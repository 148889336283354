import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deactivateSubscription } from '@/entities/subscription';
import { SUBSCRIPTION_DELETE_MUTATION_KEY, SUBSCRIPTIONS_QUERY_KEY } from '@/shared/constants';

const useDeactivateSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending: isDeactivating } = useMutation({
    mutationKey: [SUBSCRIPTION_DELETE_MUTATION_KEY],
    mutationFn: deactivateSubscription,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUBSCRIPTIONS_QUERY_KEY],
      }),
  });
  return { deactivateSubscription: mutate, isDeactivating };
};

export default useDeactivateSubscription;
