import { RefObject } from 'react';
import { Flex, Skeleton } from 'antd';
import RepeatComponent from '@/shared/components/RepeatComponent';
import styles from './styles.module.css';
import useSelectListLoader from './useSelectListLoader';

type Props = {
  resizableRef: RefObject<HTMLDivElement>;
};

const SelectListLoader = ({ resizableRef }: Props) => {
  const { repeatTimes, getTitleWidth } = useSelectListLoader(resizableRef);

  return (
    <Flex className={styles.loaderWrapper}>
      <RepeatComponent
        times={repeatTimes}
        renderCallback={(index) => (
          <Skeleton
            key={index}
            className={styles.skeleton}
            avatar={{ className: styles.skeletonAvatar, shape: 'square', size: 16 }}
            title={{ className: styles.skeletonTitle, width: getTitleWidth(index) }}
            paragraph={false}
            active
          />
        )}
      />
    </Flex>
  );
};

export default SelectListLoader;
