import { CSSProperties } from 'react';
import { Flex, Typography } from 'antd';
import { COPIES } from '@/shared/constants';
import { timeService } from '@/shared/services';
import styles from '../styles.module.css';
import { SourceDatesTooltipProps } from '../types';

const CREATED = 'Створено';
const ACTIVATED_AT = 'Активовано';
const DEACTIVATED_AT = 'Деактивовано';
const LAST_ACTIVE_AT = 'Остання активність';
const OVERLAY_STYLE: CSSProperties = { maxWidth: '500px' };

type Params = Pick<SourceDatesTooltipProps, 'lastActiveAt' | 'createdAt' | 'statusChangedAt' | 'isActivated'>;

const useSourceDatesTooltip = ({ lastActiveAt, createdAt, statusChangedAt, isActivated }: Params) => {
  const title = (
    <Flex
      vertical
      className={styles.tooltipTitleWrapper}
    >
      <Typography.Text>
        {CREATED}: {timeService.getFormattedFullTimeOrDate(createdAt)}
      </Typography.Text>
      <Typography.Text>
        {isActivated ? ACTIVATED_AT : DEACTIVATED_AT}: {timeService.getFormattedFullTimeOrDate(statusChangedAt)}
      </Typography.Text>
      <Typography.Text>
        {LAST_ACTIVE_AT}: {lastActiveAt ? timeService.getFormattedFullTimeOrDate(lastActiveAt) : COPIES.NO_ACTIVITY}
      </Typography.Text>
    </Flex>
  );

  return {
    title,
    overlayStyle: OVERLAY_STYLE,
  };
};

export default useSourceDatesTooltip;
