import { MenuItemType } from 'antd/es/menu/interface';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import {
  useFilesQueryKey,
  useFoxRecordsQueryParams,
  useGetFiles,
  useNavigateToRecord,
} from '@/entities/source/@fox/components/RecordsTab/hooks';
import { getAudioUrl } from '@/entities/source/@fox/components/RecordsTab/utils';
import { File } from '@/entities/source/@fox/types';
import { VoiceOffIcon, VoiceOnIcon } from '@/shared/assets';
import { useConfirmModal, useDeleteInfiniteItem, useIsMobile, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import {
  DELETE_MODAL_CONTENT,
  DELETE_MODAL_TITLE,
  ItemKey,
  ItemLabel,
  MARK_VOICE_EXIST,
  MARK_VOICE_SKIP,
} from '../constants';
import { RecordActionsProps } from '../types';
import useDeleteRecord from './useDeleteRecord';
import useDownloadRecord from './useDownloadRecord';
import useMarkVoiceExist from './useMarkVoiceExist';
import useMarkVoiceSkip from './useMarkVoiceSkip';
import useUpdateRecordMetadataCache from './useUpdateRecordMetadataCache';

interface Params extends RecordActionsProps {}

const useRecordActions = ({ id, fileName, hasVoice, onDelete }: Params) => {
  const { isDownloading, downloadRecord } = useDownloadRecord();
  const { sourceId, tabEntityId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const { filesList } = useGetFiles({ options: { enabled: false }, queryParams });
  const navigateToRecord = useNavigateToRecord();
  const filesQueryKey = useFilesQueryKey();
  const isMobile = useIsMobile();

  const updateRecordMetadataCache = useUpdateRecordMetadataCache(id);
  const handleToggleVoiceSuccess = () => updateRecordMetadataCache({ hasVoice: !hasVoice });

  const { markVoiceExist, isMarkVoiceExistLoading } = useMarkVoiceExist({
    onSuccess: handleToggleVoiceSuccess,
  });
  const { markVoiceSkip, isMarkVoiceSkipLoading } = useMarkVoiceSkip({
    onSuccess: handleToggleVoiceSuccess,
  });

  const toggleVoiceBtnLabel = hasVoice ? MARK_VOICE_SKIP : MARK_VOICE_EXIST;
  const toggleVoiceBtnIcon = hasVoice ? <VoiceOffIcon /> : <VoiceOnIcon />;
  const isToggleVoiceBtnLoading = isMarkVoiceExistLoading || isMarkVoiceSkipLoading;
  const handleToggleVoiceClick = hasVoice ? () => markVoiceSkip(id) : () => markVoiceExist(id);

  const deleteRecordFromCache = useDeleteInfiniteItem<File>(filesQueryKey);

  const { deleteRecord, isRecordDeleting } = useDeleteRecord({
    onSuccess: () => {
      onDelete?.();
      if (tabEntityId === id) {
        navigateToRecord({
          routeParams: {
            sourceId,
            tabEntityId: filesList.find((file) => file.id !== tabEntityId)?.id,
          },
          options: {
            withAllQueryParams: true,
          },
        });
      }
      deleteRecordFromCache('id', id);
    },
  });

  const handleDeleteRecord = useConfirmModal({
    title: DELETE_MODAL_TITLE,
    content: DELETE_MODAL_CONTENT,
    onOk: async () => {
      if (!id) return;

      deleteRecord(id);
    },
  });

  const withStopPropagation =
    (callback: VoidFunction): MenuClickEventHandler =>
    (e) => {
      e.domEvent.stopPropagation();
      callback();
    };

  const toggleVoiceItem: MenuItemType = {
    key: ItemKey.ToggleVoice,
    label: toggleVoiceBtnLabel,
    icon: toggleVoiceBtnIcon,
    onClick: withStopPropagation(handleToggleVoiceClick),
  };

  const items: MenuItemType[] = [
    ...(isMobile ? [toggleVoiceItem] : []),
    {
      key: ItemKey.Download,
      label: ItemLabel.Download,
      icon: <DownloadOutlined />,
      onClick: withStopPropagation(() => downloadRecord(getAudioUrl(id), fileName)),
    },
  ];

  const dangerItems: MenuItemType[] = [
    {
      key: ItemKey.Delete,
      label: ItemLabel.Delete,
      icon: <DeleteOutlined />,
      onClick: withStopPropagation(handleDeleteRecord),
    },
  ];

  return {
    toggleVoiceBtnTooltipTitle: toggleVoiceBtnLabel,
    toggleVoiceBtnIcon,
    isToggleVoiceBtnLoading,
    handleToggleVoiceClick,
    items,
    dangerItems,
    isLoading: (isMobile && isToggleVoiceBtnLoading) || isDownloading || isRecordDeleting,
    isMobile,
  };
};

export default useRecordActions;
