export const DISPLAY_MAP_PANEL = 'Відображення панелі мапи';
export const FULL_SCREEN_MODE = 'Повноекранний режим';
export const DISPLAY_BATTLE_LINE = 'Відображення ЛБЗ';
export const ELEVATION_TILES = 'Підкладка Мапи висот';
export const GOOGLE_TILES = 'Підкладка Google map';
export const OPEN_STREET_TILES = 'Підкладка Open street map';
export const BLACK_WHITE_TILES = 'Підкладка Темна / Світла';
export const CHANGE_BINDING_TYPE = 'Зміна типу привʼязок';
export const ZOOM_IN_OUT = 'Збільшити / зменшити';
export const ROTATE = 'Покрутити';
export const TILT = 'Нахилити';
export const MOVE = 'Перемістити';
