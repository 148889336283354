import { ClipboardEvent } from 'react';
import { idEncoderService } from '@/shared/services/IdEncoderService';

const normalizeInputPastedData = (event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { activeElement } = document;

  if (!activeElement) return;
  const inputValue: string = 'value' in activeElement ? (activeElement.value as string) : '';
  const selectedText = window.getSelection()?.toString();
  const selectionPosition = 'selectionStart' in activeElement ? (activeElement.selectionStart as number) : 0;
  const pastedText = event.clipboardData.getData('text');
  const normalizedPastedText = idEncoderService.normalize(pastedText);
  const normalizedInputText = selectedText
    ? inputValue.replace(selectedText, normalizedPastedText)
    : inputValue.slice(0, selectionPosition) + normalizedPastedText + inputValue.slice(selectionPosition);

  setNativeValue(activeElement, normalizedInputText);
  activeElement.dispatchEvent(new Event('input', { bubbles: true }));

  event.preventDefault();
};

function setNativeValue(element: Element, value: unknown) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

export default normalizeInputPastedData;
