import { ZOOM_STEP } from '../constants';
import { SpectrogramProps, ZoomData } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  zoomData: ZoomData;
}

const getOneStepInData = ({ zoomData, startFrequency, endFrequency }: Params) => {
  const currentStartFrequency = zoomData?.startZoomFrequency ?? startFrequency;
  const currentEndFrequency = zoomData?.endZoomFrequency ?? endFrequency;
  const oneStepFrequencies = ((endFrequency - startFrequency) / 100) * ZOOM_STEP;
  const zoomOneStepInStartFrequency = getRoundedFloat(currentStartFrequency + oneStepFrequencies);
  const zoomOneStepInEndFrequency = getRoundedFloat(currentEndFrequency - oneStepFrequencies);
  const isRestrictedZoomLevel = zoomOneStepInEndFrequency - zoomOneStepInStartFrequency < 0.025;

  const frequenciesSelectedBoundaries = {
    startSelectedFrequency: zoomOneStepInStartFrequency,
    endSelectedFrequency: zoomOneStepInEndFrequency,
  };

  const currentSelectedBoundaries = {
    startSelectedFrequency: currentStartFrequency,
    endSelectedFrequency: currentEndFrequency,
  };

  return {
    isRestrictedZoomLevel,
    frequenciesSelectedBoundaries: isRestrictedZoomLevel ? currentSelectedBoundaries : frequenciesSelectedBoundaries,
  };
};

export default getOneStepInData;
