import { type MouseEvent, type KeyboardEvent } from 'react';
import { MenuItemType } from 'antd/es/menu/interface';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { UseFoxFrequencyActionsParams } from '../types';
import useActivateFoxFrequency from './useActivateFoxFrequency';
import useDeactivateFoxFrequency from './useDeactivateFoxFrequency';
import useFoxFrequenciesWithUpdateStatus from './useFoxFrequenciesWithUpdateStatus';

export enum ItemLabel {
  Activate = 'Активувати частоту',
  Deactivate = 'Деактивувати частоту',
}
export enum ItemKey {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

interface MenuClickEventHandler {
  domEvent: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>;
}

interface Params extends Pick<UseFoxFrequencyActionsParams, 'foxFrequencyId' | 'foxFrequencyStatus'> {}

const useItems = ({ foxFrequencyId, foxFrequencyStatus }: Params) => {
  const { sourceId } = useParams<SourceRouteParams>();
  const updateFoxFrequencyStatus = useFoxFrequenciesWithUpdateStatus();
  const { activateFoxFrequency } = useActivateFoxFrequency({
    onSuccess: () => {
      updateFoxFrequencyStatus({ sourceId, foxFrequencyId, foxFrequencyStatus: FoxFrequencyStatus.Active });
    },
  });
  const { deactivateFoxFrequency } = useDeactivateFoxFrequency({
    onSuccess: () => {
      updateFoxFrequencyStatus({
        sourceId,
        foxFrequencyId,
        foxFrequencyStatus: FoxFrequencyStatus.Inactive,
      });
    },
  });

  const handleActivateFoxFrequency = ({ domEvent }: MenuClickEventHandler) => {
    if (!sourceId) return;

    domEvent.stopPropagation();
    activateFoxFrequency({ sourceId, foxFrequencyId });
  };

  const handleDeactivateFoxFrequency = ({ domEvent }: MenuClickEventHandler) => {
    if (!sourceId) return;

    domEvent.stopPropagation();
    deactivateFoxFrequency({ sourceId, foxFrequencyId });
  };

  const items: MenuItemType[] =
    foxFrequencyStatus === FoxFrequencyStatus.Inactive
      ? [
          {
            key: ItemKey.Activate,
            label: ItemLabel.Activate,
            icon: <PlayCircleOutlined />,
            onClick: handleActivateFoxFrequency,
          },
        ]
      : [
          {
            key: ItemKey.Deactivate,
            label: ItemLabel.Deactivate,
            icon: <PauseCircleOutlined />,
            onClick: handleDeactivateFoxFrequency,
          },
        ];

  return items;
};

export default useItems;
