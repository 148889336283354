/* eslint no-continue: 0 */
import { EncoderService } from '@/shared/types';

export class IdEncoderService implements EncoderService {
  private readonly specialSpaces = {
    punctuation: '\u2008',
    medium: '\u205F',
    figure: '\u2007',
    narrow: '\u202F',
    double: '\u200A',
  };

  private readonly binarySeparator = {
    start: this.specialSpaces.punctuation,
    separator: this.specialSpaces.medium,
    end: this.specialSpaces.figure,
  };

  private specialSpacesList = [
    this.specialSpaces.punctuation,
    this.specialSpaces.medium,
    this.specialSpaces.figure,
    this.specialSpaces.narrow,
    this.specialSpaces.double,
  ];

  private doubledSpecialSpace = this.specialSpaces.double;

  private regularSpace = ' ';

  private readonly binaryToChar: Record<string, Record<string, string>> = {
    [this.regularSpace]: {
      '0': this.specialSpaces.narrow,
      '1': `${this.specialSpaces.double}${this.specialSpaces.double}`,
    },
  };

  private readonly charToBinary: Record<string, string> = {
    [this.specialSpaces.narrow]: '0',
    [`${this.specialSpaces.double}${this.specialSpaces.double}`]: '1',
  };

  public encode({ text, injectedId }: { text: string; injectedId: number }): string {
    const stringifiedInjectedId = injectedId.toString();
    if (stringifiedInjectedId.length === 0) return text;

    const idSum = this.getDigitSum(injectedId);
    const binaryId = this.toBinary(stringifiedInjectedId);
    const binaryIdSum = this.toBinary(idSum.toString());
    const separatorLength = Object.keys(this.binarySeparator).length;
    const charsToReplace = Array.from(text).filter((char) => this.binaryToChar[char]);

    const singleInjectionLength = binaryId.length + binaryIdSum.length + separatorLength;
    const maxInjections = Math.floor(charsToReplace.length / singleInjectionLength);

    if (maxInjections === 0) return text;

    let binaryIndex = 0;
    let binaryIdSumIndex = 0;
    let injectionCount = 0;
    let prefixAdded = false;
    let separatorAdded = false;

    return Array.from(text)
      .map((char) => {
        if (injectionCount >= maxInjections) return char;

        if (!prefixAdded && this.binaryToChar[char]) {
          prefixAdded = true;
          return this.binarySeparator.start;
        }

        if (binaryIndex < binaryId.length && this.binaryToChar[char]) {
          return this.binaryToChar[char][binaryId[binaryIndex++]];
        }

        if (binaryIndex === binaryId.length && binaryIdSumIndex < binaryIdSum.length && this.binaryToChar[char]) {
          if (!separatorAdded) {
            separatorAdded = true;
            return this.binarySeparator.separator;
          }
          return this.binaryToChar[char][binaryIdSum[binaryIdSumIndex++]];
        }

        if (binaryIndex === binaryId.length && binaryIdSumIndex === binaryIdSum.length && this.binaryToChar[char]) {
          binaryIndex = 0;
          binaryIdSumIndex = 0;
          prefixAdded = false;
          separatorAdded = false;
          injectionCount++;
          return this.binarySeparator.end;
        }

        return char;
      })
      .join('');
  }

  public decode(text: string): string {
    const results: string[] = [];
    let isInsideEncodedId = false;
    let isAfterSeparator = false;
    let binaryId = '';
    let binaryIdSum = '';

    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      const doubleChar = text.slice(i, i + 2);

      if (char === this.binarySeparator.start) {
        isInsideEncodedId = true;
        isAfterSeparator = false;
        binaryId = '';
        binaryIdSum = '';
      } else if (char === this.binarySeparator.end) {
        if (isInsideEncodedId) {
          const id = this.fromBinary(binaryId);
          const idSum = this.fromBinary(binaryIdSum);
          const isIdCorrect = parseInt(idSum, 10) === this.getDigitSum(parseInt(id, 10));
          const result = isIdCorrect ? `/---${id}---/` : '/---broken id---/';
          results.push(result);
        }
        isInsideEncodedId = false;
        isAfterSeparator = false;
      } else if (!isAfterSeparator && char === this.binarySeparator.separator) {
        isAfterSeparator = true;
        binaryIdSum = '';
      } else if (isInsideEncodedId) {
        if (this.charToBinary[doubleChar]) {
          if (!isAfterSeparator) {
            binaryId += this.charToBinary[doubleChar];
          } else {
            binaryIdSum += this.charToBinary[doubleChar];
          }
          i++;
        } else if (this.charToBinary[char]) {
          if (!isAfterSeparator) {
            binaryId += this.charToBinary[char];
          } else {
            binaryIdSum += this.charToBinary[char];
          }
        }
      }
    }

    return results.join(', ');
  }

  private toBinary(text: string): string {
    return text
      .split('')
      .map((char) => char.charCodeAt(0).toString(2).padStart(8, '0'))
      .join('');
  }

  private fromBinary(binary: string): string {
    return (
      binary
        .match(/.{1,8}/g)
        ?.map((byte) => String.fromCharCode(parseInt(byte, 2)))
        .join('') || ''
    );
  }

  private getDigitSum(num: number): number {
    return num
      .toString()
      .split('')
      .map((digit) => parseInt(digit, 10))
      .reduce((sum, digit) => sum + digit, 0);
  }

  public normalize(text: string): string {
    const charArr = text.split('');
    let result = '';
    let shouldSkipIteration = false;

    for (let i = 0; i < charArr.length; i++) {
      if (shouldSkipIteration) {
        shouldSkipIteration = false;
        continue;
      }

      if (charArr[i] === this.doubledSpecialSpace && charArr[i + 1] === this.doubledSpecialSpace) {
        result += this.regularSpace;
        shouldSkipIteration = true;
        continue;
      }

      if (this.specialSpacesList.includes(charArr[i])) {
        result += this.regularSpace;
        continue;
      }

      result += charArr[i];
    }

    return result;
  }
}

export const idEncoderService = new IdEncoderService();
