import { useState } from 'react';
import { getUnitUsers } from '@/entities/unit/api';
import { UnitUser } from '@/entities/unit/types';
import { UNIT_USERS_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery, UseInfiniteListQueryOptions, useUpdateFoffset } from '@/shared/hooks';

interface Params {
  options?: Omit<UseInfiniteListQueryOptions<UnitUser>, 'queryKey' | 'initialPageParam'>;
  unitId: string;
}

const useGetUnitUsers = ({ options, unitId }: Params = { options: {}, unitId: '' }) => {
  const [foffset, setFoffset] = useState<string | null>(null);

  const {
    data,
    list: unitUsersList,
    getListRef: getUnitUserRef,
    isLoading: isUnitUsersLoading,
    isFetching: isUnitUsersFetching,
    refetch: refetchUnitUsers,
  } = useInfiniteListQuery({
    ...options,
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [UNIT_USERS_QUERY_KEY, unitId],
    queryFn: ({ pageParam }) => {
      return getUnitUsers({
        unitId,
        queryParams: {
          page: pageParam as number,
          foffset: pageParam !== 1 ? foffset : null,
        },
      });
    },
    initialPageParam: 1,
  });

  useUpdateFoffset({ data, setFoffset });

  return {
    unitUsersList,
    getUnitUserRef,
    isUnitUsersLoading,
    isUnitUsersFetching,
    refetchUnitUsers,
  };
};

export default useGetUnitUsers;
