import { FC, PropsWithChildren } from 'react';
import { Flex, Typography } from 'antd';
import Icon from '@/shared/components/ui/Icon';
import { RoleName } from '@/shared/constants';
import { useIsUserRole } from '@/shared/hooks';
import styles from './styles.module.css';

const ALL_ROLES = Object.values(RoleName);

type Props = {
  icon?: FC;
  iconClassName?: string;
  label: string;
  memoAvailableForRoles?: RoleName[];
};

const HotkeyRow = ({
  icon,
  iconClassName,
  label,
  memoAvailableForRoles = ALL_ROLES,
  children: hotkey,
}: PropsWithChildren<Props>) => {
  const hasAccess = useIsUserRole({ memoRoles: memoAvailableForRoles });

  if (!hasAccess) return null;

  return (
    <Flex className={styles.hotkeyRow}>
      <Flex className={styles.hotkeyLabel}>
        {icon && (
          <Icon
            className={iconClassName}
            component={icon}
          />
        )}
        <Typography.Text>{label}</Typography.Text>
      </Flex>
      <Flex
        align="center"
        gap={5}
      >
        {hotkey}
      </Flex>
    </Flex>
  );
};

export default HotkeyRow;
