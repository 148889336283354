import { useSearchParams } from 'react-router';
import omit from 'lodash/omit';
import { FiltersDrawerProps, FilterQueryParam } from '../types';

const SKIP_QUERY_PARAMS = ['Search', 'Order', 'Sort'];

interface Params extends Pick<FiltersDrawerProps, 'filterQueryParam'> {}

const useFiltersDrawer = ({ filterQueryParam }: Params) => {
  const [, setSearchParams] = useSearchParams();

  const handleFiltersReset = () => {
    if (!filterQueryParam) return;

    setSearchParams((prevSearchParams) => {
      Object.values(omit(filterQueryParam, SKIP_QUERY_PARAMS) as FilterQueryParam).forEach((paramName) => {
        prevSearchParams.delete(paramName);
      });

      return prevSearchParams;
    });
  };

  return {
    handleFiltersReset,
  };
};

export default useFiltersDrawer;
