interface Params {
  endFrequency: number;
  firstLineFrequency: number;
  gap: number;
}

const getFrequenciesList = ({ endFrequency, firstLineFrequency, gap }: Params) => {
  if (endFrequency <= firstLineFrequency) return [];

  let lineFrequency = firstLineFrequency;
  const result: number[] = [];
  while (lineFrequency < endFrequency) {
    result.push(parseFloat(lineFrequency.toFixed(4)));
    lineFrequency += gap;
  }
  return result;
};

export default getFrequenciesList;
