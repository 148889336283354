type Options = {
  isMobile?: boolean;
};

export class HotkeysHelperService {
  getIsElementInDom = (query: string) => {
    return !!document.querySelector(query);
  };

  getIsOverlayComponentOpen = ({ isMobile = false }: Options = {}) => {
    if (isMobile) return window.location.search.includes('modal=true');

    return (
      document.querySelector('.ant-modal-wrap:not([style*="display: none"])') ||
      document.querySelector('.ant-drawer-open')
    );
  };

  getIsInputOrTextarea = (e: KeyboardEvent) => {
    return e.target instanceof HTMLElement && (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA');
  };
}

export default new HotkeysHelperService();
