import { LoadingWrapper, ScrollUpToRefresh, Table } from '@/shared/components';
import { useUnitSourcesTab } from './hooks';

const UnitSourcesTab = () => {
  const {
    sourcesList,
    isLoading,
    columns,
    handleScrollUpToReFetch,
    memoSource,
    handleCloseModal,
    handleUnitInvalidate,
    renderEditSourceModal,
  } = useUnitSourcesTab();

  return (
    <LoadingWrapper
      isLoading={isLoading}
      isFixedOverlay
    >
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={sourcesList.length > 0}
        onRefresh={handleScrollUpToReFetch}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={sourcesList}
        />
      </ScrollUpToRefresh>
      {memoSource &&
        renderEditSourceModal({
          memoSource,
          onClose: handleCloseModal,
          onEdit: handleUnitInvalidate,
          isNameEditDisabled: false,
        })}
    </LoadingWrapper>
  );
};

export default UnitSourcesTab;
