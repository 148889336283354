import { useCallback, useEffect, useState } from 'react';
import { SpectrogramFrequency } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/types';
import { getFrequencyByPosition } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/utils';
import { getNormalizedNewFrequency } from '@/entities/source/@fox/components/ScanningTab/utils';
import { NOTIFICATION_TYPES, STATUS_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { NewFrequencyProps } from '../types';
import { isUniqueFrequency } from '../utils';

const NEW_FREQUENCY_ID = 'new-frequency';
const OUT_OF_RANGE = 'Поза діапазоном';

interface Params
  extends Pick<
    NewFrequencyProps,
    | 'isMouseDownOnCanvas'
    | 'endFrequency'
    | 'startFrequency'
    | 'memoFrequencyPosition'
    | 'memoOnCreateFrequency'
    | 'canvasWidth'
    | 'memoFrequencies'
    | 'isAddingNewFrequencyEnabled'
  > {}

const useNewFrequency = ({
  isMouseDownOnCanvas,
  endFrequency,
  startFrequency,
  memoOnCreateFrequency,
  memoFrequencyPosition,
  canvasWidth,
  memoFrequencies,
  isAddingNewFrequencyEnabled,
}: Params) => {
  const { openNotification } = useNotificationContext();
  const [newFrequency, setNewFrequency] = useState<null | number>(null);

  useEffect(() => {
    if (
      newFrequency ||
      !canvasWidth ||
      !memoFrequencyPosition.down ||
      !isMouseDownOnCanvas ||
      !isAddingNewFrequencyEnabled
    )
      return;

    const frequency = Number(
      getFrequencyByPosition({
        startFrequency,
        endFrequency,
        canvasWidth,
        position: Math.round(memoFrequencyPosition.down),
      })
    );
    if (memoFrequencyPosition.up === memoFrequencyPosition.down && newFrequency !== frequency) {
      setNewFrequency(frequency);
    }
  }, [
    newFrequency,
    memoFrequencyPosition,
    startFrequency,
    endFrequency,
    canvasWidth,
    isMouseDownOnCanvas,
    isAddingNewFrequencyEnabled,
  ]);

  const handleCreateFrequency = useCallback(
    async ({ frequency }: SpectrogramFrequency) => {
      try {
        if (frequency < startFrequency || frequency > endFrequency) {
          openNotification({
            type: NOTIFICATION_TYPES.ERROR,
            title: STATUS_TYPES.ERROR,
            description: OUT_OF_RANGE,
          });
        } else if (isUniqueFrequency({ frequencyValue: frequency, frequencies: memoFrequencies })) {
          const normalizedFrequency = getNormalizedNewFrequency(frequency);
          await memoOnCreateFrequency({ value: normalizedFrequency, isDigital: true });
        }
      } finally {
        setNewFrequency(null);
      }
    },
    [memoOnCreateFrequency, memoFrequencies, startFrequency, endFrequency]
  );

  const handleDeleteNewFrequency = useCallback(async () => {
    setNewFrequency(null);
  }, []);

  return {
    newFrequency,
    newFrequencyId: NEW_FREQUENCY_ID,
    handleCreateFrequency,
    handleDeleteNewFrequency,
  };
};

export default useNewFrequency;
