import { Form, Flex, Row } from 'antd';
import FoxFrequencyCreateButtons from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyCreateButtons';
import IsDigitalToggle from '@/entities/source/@fox/components/ScanningTab/components/IsDigitalToggle';
import { NO_FREQUENCY, NO_NOTES } from '@/entities/source/@fox/components/ScanningTab/constants';
import { getValueValidationRules } from '@/entities/source/@fox/components/ScanningTab/utils';
import { Input, TextArea } from '@/shared/components';
import { useAddFrequencyRow } from './hooks';
import styles from './styles.module.css';

interface Props {
  onCancelAddNewFoxFrequency: () => void;
}

const AddFrequencyRow = ({ onCancelAddNewFoxFrequency }: Props) => {
  const { form, source, handleFinish, handleKeyDown, handleValueInputBlur, initialValues } = useAddFrequencyRow({
    onCancelAddNewFoxFrequency,
  });

  return (
    <Row className={styles.wrapper}>
      <Form
        className={styles.form}
        form={form}
        onFinish={handleFinish}
        onKeyDownCapture={handleKeyDown}
        initialValues={initialValues}
      >
        <Form.Item
          className={styles.formItem}
          name="value"
          rules={getValueValidationRules(source?.frequencyRange)}
        >
          <Input
            onBlur={handleValueInputBlur}
            autoFocus
            placeholder={NO_FREQUENCY}
            maxLength={8}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name="note"
        >
          <TextArea
            autoSize
            placeholder={NO_NOTES}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Form.Item>
        <Flex vertical>
          <IsDigitalToggle formItemName="isDigital" />
          <FoxFrequencyCreateButtons
            onCancelClick={onCancelAddNewFoxFrequency}
            onSubmitClick={form.submit}
          />
        </Flex>
      </Form>
    </Row>
  );
};

export default AddFrequencyRow;
