import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getRadioNetworkById } from '@/entities/network/api';
import { RADIO_NETWORK_BY_ID_QUERY_KEY } from '@/shared/constants';

interface Params extends Omit<UseQueryOptions, 'queryKey'> {
  id: string;
  enabled: boolean;
}

const useGetRadioNetwork = ({ id, enabled }: Params) => {
  const { data, isLoading, isFetching, isSuccess, isError } = useQuery({
    queryKey: [RADIO_NETWORK_BY_ID_QUERY_KEY, id],
    queryFn: () => getRadioNetworkById(id),
    enabled,
  });

  return {
    isError,
    isLoading,
    isSuccess,
    isFetching,
    radioNetwork: data?.data || null,
  };
};

export default useGetRadioNetwork;
