import { ReactElement } from 'react';
import { Layout } from 'antd';
import { MobileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ProfileIcon } from '@/shared/assets';
import NavigationButton from '@/shared/components/NavigationButton';
import { COPIES } from '@/shared/constants';
import { NavigationMenu } from './components';
import styles from './styles.module.css';
import useNavigationContent from './useNavigationContent';

const HELP = 'Допомога';

export interface Props {
  notificationsButton?: ReactElement;
}

const NavigationContent = ({ notificationsButton }: Props) => {
  const {
    isTablet,
    isSidebarCollapsed,
    isSubscriptionHidden,
    handleMenuItemClick,
    handlePlaceholderClick,
    handleHotkeysOpen,
    handleSubscriptionOpen,
    handleProfileOpen,
  } = useNavigationContent();

  return (
    <>
      <Layout.Content className={styles.navigationContent}>
        <NavigationMenu onMenuItemClick={handleMenuItemClick} />
      </Layout.Content>
      <Layout.Content
        className={styles.placeholder}
        onClick={isTablet && isSidebarCollapsed ? handlePlaceholderClick : undefined}
      />
      <Layout.Footer className={styles.navigationFooter}>
        <NavigationButton
          Icon={QuestionCircleOutlined}
          label={HELP}
          onClick={handleHotkeysOpen}
        />
        {notificationsButton}
        {!isSubscriptionHidden && (
          <NavigationButton
            Icon={MobileOutlined}
            label={COPIES.SUBSCRIPTION}
            onClick={handleSubscriptionOpen}
          />
        )}
        <NavigationButton
          Icon={ProfileIcon}
          label={COPIES.PROFILE}
          onClick={handleProfileOpen}
          data-testid="profile-button"
        />
      </Layout.Footer>
    </>
  );
};

export default NavigationContent;
