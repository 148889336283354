import { FormInstance } from 'antd';

interface Params {
  form: FormInstance;
  getNormalizedNewFrequency: (value: number) => number;
}

const normalizeNewFrequencyFieldValue = ({ form, getNormalizedNewFrequency }: Params) => {
  const validationErrors = form.getFieldError('value');
  const value: string = form.getFieldValue('value');
  const numericValue = parseFloat(value);

  if (validationErrors.length > 0 || Number.isNaN(numericValue)) return;

  const normalizedValue = getNormalizedNewFrequency(numericValue);
  form.setFieldValue('value', normalizedValue.toFixed(4));
};

export default normalizeNewFrequencyFieldValue;
