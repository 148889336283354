export const TIME = 'H:mm';
export const TIME_HOUR_LONG = 'HH:mm';
export const TIME_FULL = 'H:mm:ss';
export const TIME_FULL_HOUR_LONG = 'HH:mm:ss';
export const DAY = 'D';
export const DATE = 'D MMM';
export const DATE_MONTH_LONG = 'D MMMM';
export const DATE_FULL = 'D MMM YYYY';
export const DATE_MIDDLE_MONTH_LONG_WITH_DOTES = 'D.MM.YYYY';
export const DATE_FULL_MONTH_LONG = 'D MMMM YYYY';
export const DATE_TIME = 'D MMM H:mm';
export const DATE_TIME_FULL = 'D MMM H:mm:ss';
export const DATE_FULL_TIME = 'D MMM YYYY H:mm';
export const DATE_FULL_TIME_HOUR_LONG = 'D MMM YYYY HH:mm';
export const DATE_FULL_TIME_FULL = 'D MMM YYYY H:mm:ss';
export const DATE_FULL_MONTH_LONG_TIME_FULL = 'D MMMM YYYY H:mm:ss';

export const DATE_RANGE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_RANGE_FORMAT_SHORT = 'DD/MM/YY HH:mm';
