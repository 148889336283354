import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { updateUnitUser as updateUnitUserApi } from '@/entities/unit/api';
import { UpdateUnitUserParams, UnitUser } from '@/entities/unit/types';
import { ResponseData } from '@/shared/api';
import { UPDATE_UNIT_USER_MUTATION_KEY } from '@/shared/constants';

interface Params {
  options?: UseMutationOptions<ResponseData<UnitUser>, unknown, UpdateUnitUserParams>;
}

const useUpdateUnitUser = ({ options }: Params = {}) => {
  const { mutate: updateUnitUser, isPending: isUnitUserUpdating } = useMutation({
    ...options,
    mutationKey: [UPDATE_UNIT_USER_MUTATION_KEY],
    mutationFn: ({ userId, payload }) => {
      return updateUnitUserApi({ userId, payload });
    },
  });

  return {
    updateUnitUser,
    isUnitUserUpdating,
  };
};

export default useUpdateUnitUser;
