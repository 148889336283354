import { Query, QueryKey } from '@tanstack/react-query';
import { errorHandlingService } from '@/shared/api';
import { QUERY_ERROR_COPIES, STATUS_TYPES, COPIES } from '@/shared/constants';
import { NotificationProps } from '@/shared/context';
import { ValueOfQueryKeys } from '@/shared/types';

export const showQueryNotification = (
  err: unknown,
  query: Query<unknown, unknown, unknown, QueryKey>,
  openNotification: (args: NotificationProps) => void
) => {
  if (errorHandlingService.isNetworkError(err) || errorHandlingService.isErrorCauseNetworkError(err)) {
    openNotification({
      type: 'error',
      title: COPIES.CONNECTION_FAILED,
    });
  } else {
    const key = query.queryKey[0] as ValueOfQueryKeys;
    const description = QUERY_ERROR_COPIES[key];

    if (description) {
      openNotification({
        type: 'error',
        title: STATUS_TYPES.ERROR,
        description,
      });
    }
  }
};
