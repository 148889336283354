import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getRadioNetworks } from '@/entities/network/api';
import { selectIsNetworkListUpdating } from '@/entities/network/slices';
import { NETWORKS_QUERY_KEY } from '@/shared/constants';
import { useAppSelector, useInfiniteListQuery, useUpdateFoffset, useParams } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import { RadioNetworkRouteParams } from '@/shared/types';
import { adaptV2ToV3RadioNetwork } from '@/shared/utils';
import { useNavigateToRadioNetwork } from './useNavigateToRadioNetwork';

type Params = {
  queryParams: string;
  refetchInterval?: number;
  isFrequencyDragging?: boolean;
};

export const useGetRadioNetworks = ({ queryParams, refetchInterval = 0, isFrequencyDragging = false }: Params) => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const navigate = useNavigateToRadioNetwork();
  const isNetworkListUpdating = useAppSelector(selectIsNetworkListUpdating);
  const [foffset, setFoffset] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => {
    return [NETWORKS_QUERY_KEY, queryParams];
  }, [queryParams]);

  const memoRemove = useCallback(() => {
    queryClient.removeQueries({ queryKey });
  }, [queryClient, queryKey]);

  const {
    list: listV2,
    data,
    isFetching,
    isLoading,
    forceFetchNextPage,
    refetch,
  } = useInfiniteListQuery({
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: ({ pageParam }) => {
      return getRadioNetworks({
        currentPage: pageParam as number,
        queryParams: queryParamsService.getQueryParamsWithFoffset(pageParam as number, queryParams, foffset),
      });
    },
    initialPageParam: 1,
    refetchInterval,
    shouldFetchNextPage: !isFrequencyDragging && !isNetworkListUpdating,
  });
  const list = useMemo(() => listV2.map(adaptV2ToV3RadioNetwork), [listV2]);

  useUpdateFoffset({ data, setFoffset });

  useEffect(() => {
    if (list.length <= 0) return;

    // select the first available frequency / network by default
    if (!radioNetworkId) {
      navigate({ routeParams: { radioNetworkId: list[0].id }, options: { withAllQueryParams: true } });
    }
  }, [list, radioNetworkId, navigate]);

  return {
    networks: list,
    isNetworksFetching: isFetching,
    isNetworksLoading: isLoading,
    forceFetchNextPage,
    refetch,
    memoRemove,
  };
};
