import DOMPurify from 'dompurify';
import uniqueId from 'lodash/uniqueId';
import { Transcription } from '@/entities/transcript/types';
import { EntityType, SEARCH_MIN_LENGTH } from '@/shared/constants';
import {
  Entity,
  TagEntity,
  LocationEntity,
  FactionEntity,
  CallSignEntity,
  CodeEntity,
  SearchEntity,
} from '@/shared/types';
import { ALLOWED_CHARS, TEMPORARY_WRAPPERS } from './constants';
import { ParseOptions, ParseResponse } from './types';

export class TranscriptMessageService {
  private allowedTags = ['b', 'strong', 'i', 'em', 'u', 'del', 'span', 'mark', 'code'];

  private allowedAttrs = ['style'];

  private searchOperators = {
    PLUS: '+',
    MINUS: '-',
    STAR: '*',
  };

  private parsedMessage = '';

  private entities: Entity[] = [];

  private parsedEntities: Record<string, Entity> = {};

  constructor(private htmlService: typeof DOMPurify) {}

  private reset() {
    this.parsedMessage = '';
    this.entities = [];
    this.parsedEntities = {};
  }

  private sanitize(text: string) {
    return this.htmlService.sanitize(text, {
      ALLOWED_TAGS: this.allowedTags,
      ALLOWED_ATTR: this.allowedAttrs,
    });
  }

  // avoid to add more operators if operator is already exists
  private hasSearchOperator(str?: string) {
    const { PLUS, MINUS } = this.searchOperators;
    return str?.startsWith(PLUS) || str?.startsWith(MINUS);
  }

  private replaceSearchOperatorARRAY(str: string) {
    // operator "ARRAY", example: +(word1 word2 ...wordN) OR -(word1 word2 ...wordN)
    const regexWithARRAY = /[+-]\([+\-*|\d\p{L}\s]+\)/gu;

    return str.replace(regexWithARRAY, (match: string) => {
      const bracketsOperator = match[0];
      const bracketsContent = match.slice(2, -1).trim();
      const replaceSpaces = (_: string, index: number) => {
        return this.hasSearchOperator(bracketsContent[index + 1]) ? ' ' : ` ${bracketsOperator}`;
      };

      return `${bracketsOperator}${bracketsContent.replace(/\s+/g, replaceSpaces)}`;
    });
  }

  private replaceSearchOperatorOR(str: string) {
    const { PLUS } = this.searchOperators;
    const addPlus = (word: string) => (this.hasSearchOperator(word) ? word : `${PLUS}${word}`);

    // operator "OR", example: word1|word2|...wordN
    const regexWithOR = /\S{1,30}\|\S+/g;

    return str.replace(regexWithOR, (match: string) => {
      return match.split('|').map(addPlus).join(' ');
    });
  }

  private getSearchKeys(search: string): string[] {
    const strictRegex = /"([^"]*)"/;
    const strictSearchKeys = strictRegex.exec(search);

    const regularRegex = /"([^"]+)"|'([^']+)'|\S+/g;
    const getRegularSearchKeys = (str: string) => {
      const preparedSearch = this.replaceSearchOperatorOR(this.replaceSearchOperatorARRAY(str));
      return preparedSearch.match(regularRegex)?.map((key) => key.replace(/^"(.*)"$/, '$1')) ?? [];
    };

    // Rule: Search value to match text between double quotes
    if (strictSearchKeys && strictSearchKeys.length > 1) {
      const [strictKeyWrappedByQuotes, strictKey] = strictSearchKeys;
      const [beforeStrictKeyString, afterStrictKeyString] = search.split(strictKeyWrappedByQuotes);

      return [
        strictKey.trim(),
        ...getRegularSearchKeys(beforeStrictKeyString),
        ...this.getSearchKeys(afterStrictKeyString),
      ];
    }

    return getRegularSearchKeys(search);
  }

  private getParseRegex = (alias: string, entityType: EntityType) => {
    const escapeSpecialChars = (text: string) => {
      const regex = new RegExp(`[${ALLOWED_CHARS}]`, 'g');
      return text.replace(regex, '\\$&');
    };

    const getRegexRules = (str: string) => {
      const escapedStr = escapeSpecialChars(str);

      return {
        freeEndRegexRule: `(?<=[${ALLOWED_CHARS}\\s]|^)${escapedStr}`,
        fullMatchRegexRule: `(?<=[${ALLOWED_CHARS}\\s]|^)${escapedStr}(?=[${ALLOWED_CHARS}\\s]|$)`,
      };
    };

    if (entityType === EntityType.SEARCH) {
      const { PLUS, STAR } = this.searchOperators;
      const shouldUseFullMatch = alias.startsWith(PLUS) && !alias.endsWith(STAR);

      // clear alias from search operators
      const { freeEndRegexRule, fullMatchRegexRule } = getRegexRules(alias.replace(PLUS, '').replace(STAR, ''));
      const searchRegexRule = shouldUseFullMatch ? fullMatchRegexRule : freeEndRegexRule;

      return new RegExp(searchRegexRule, 'gi');
    }

    const { fullMatchRegexRule } = getRegexRules(alias);
    return new RegExp(fullMatchRegexRule, 'gi');
  };

  private prepareClusterMessage(message: string) {
    // skips empty lines and lines starting with `укх` or `нв`
    return message
      .split(/\r?\n/)
      .map((line) => line.trim().replace(/^(укх.*|нв.*)/gi, ''))
      .filter(Boolean)
      .join('\n');
  }

  private prepareMessage(message: string, isCluster?: boolean) {
    if (isCluster) {
      this.parsedMessage = this.prepareClusterMessage(this.sanitize(message));
    } else {
      this.parsedMessage = this.sanitize(message);
    }

    return this;
  }

  private prepareCategories(categories: Transcription['categories'], tags: Transcription['tags']) {
    const tagEntities: TagEntity[] = tags
      .filter(({ text }) => text)
      .map((tag) => {
        const { color } = categories.find(({ id }) => id === tag.subCategoryId)?.parent ?? {};
        return {
          ...tag,
          color,
          entityType: EntityType.TAG,
        };
      });

    this.entities = this.entities.concat(...tagEntities);
    return this;
  }

  private prepareLocations(locations: Transcription['locations']) {
    const locationEntities: LocationEntity[] = locations
      .filter(({ alias }) => alias)
      .map(({ alias, ...rest }) => ({
        ...rest,
        text: alias,
        color: 'var(--colorLocationIcon)',
        entityType: EntityType.LOCATION,
      }));

    this.entities = this.entities.concat(...locationEntities);
    return this;
  }

  private prepareFactions(factions: Transcription['factions']) {
    const factionEntities: FactionEntity[] = factions.flatMap(({ alias: _, aliases, ...rest }) => {
      return aliases
        .filter((alias) => alias)
        .map((alias) => ({
          ...rest,
          text: alias,
          color: 'var(--colorFactionIcon)',
          entityType: EntityType.FACTION,
        }));
    });

    this.entities = this.entities.concat(...factionEntities);
    return this;
  }

  private prepareCallSigns(callSigns: Transcription['callsigns']) {
    const callSignEntities: CallSignEntity[] = callSigns
      .filter(({ alias }) => alias)
      .map(({ alias, ...rest }) => ({
        ...rest,
        text: alias,
        entityType: EntityType.CALL_SIGN,
      }));

    this.entities = this.entities.concat(...callSignEntities);
    return this;
  }

  private prepareCodes(codes: Transcription['codes']) {
    const codeEntities: CodeEntity[] = codes
      .filter(({ value }) => value)
      .map(({ name, ...rest }) => ({
        ...rest,
        text: name,
        entityType: EntityType.CODE,
      }));

    this.entities = this.entities.concat(...codeEntities);
    return this;
  }

  private prepareSearchEntities(search: string) {
    const { MINUS } = this.searchOperators;
    const searchKeys = this.getSearchKeys(search);

    const searchKeysEntities: SearchEntity[] = searchKeys
      // exclude keys which starts with operator MINUS or has a small length
      .filter((searchKey) => searchKey.length >= SEARCH_MIN_LENGTH && !searchKey.startsWith(MINUS))
      .map((searchKey) => ({
        text: searchKey,
        entityType: EntityType.SEARCH,
      }));

    this.entities = this.entities.concat(...searchKeysEntities);
    return this;
  }

  private shouldBeWrapped(text: string, entityType: EntityType): boolean {
    if ([EntityType.TAG, EntityType.LOCATION, EntityType.FACTION].includes(entityType)) return true;

    const [openTag, closeTag] = TEMPORARY_WRAPPERS[entityType];
    const cutParsedText = (str: string, tag: string) => {
      const [, ...rest] = str.split(tag);
      return rest.join(tag);
    };

    const stringAfterOpenTag = cutParsedText(text, openTag);
    if (!stringAfterOpenTag) return true;

    const stringAfterCloseTag = cutParsedText(stringAfterOpenTag, closeTag);
    if (!stringAfterCloseTag) return false;

    return this.shouldBeWrapped(stringAfterCloseTag, entityType);
  }

  private getWrappedText(entity: Entity, attachId: string) {
    const { text, color, entityType } = entity;

    const buildTemplate = (start: string, content: string, end: string) => {
      const [openTag, closeTag] = TEMPORARY_WRAPPERS[entityType];
      // this will avoid to parse the html tags context
      const encode = (str: string) => str.split('').join('</>');

      return `${openTag}${encode(start)}${content}${encode(end)}${closeTag}`;
    };

    switch (entityType) {
      case EntityType.TAG:
      case EntityType.LOCATION:
      case EntityType.FACTION: {
        const spanColor = color ?? 'initial';
        return buildTemplate(
          `<span style="text-decoration-color: ${spanColor}" data-type="underline" data-id="${attachId}" data-alias="${entityType}">`,
          text,
          '</span>'
        );
      }
      case EntityType.CALL_SIGN: {
        return buildTemplate(`<span data-id="${attachId}" data-alias="${entityType}">`, text, '</span>');
      }
      case EntityType.CODE: {
        return buildTemplate(`<span data-alias="${entityType}">`, text, `<code> {${entity.value}}</code></span>`);
      }
      case EntityType.SEARCH: {
        return buildTemplate('<mark>', text, '</mark>');
      }
      default: {
        return text;
      }
    }
  }

  private attachEntity(entity: Entity) {
    const { text: alias, entityType } = entity;
    if (!alias) return;

    const attachId = entityType !== EntityType.SEARCH ? uniqueId() : '';
    const regex = this.getParseRegex(alias, entityType);

    this.parsedMessage = this.parsedMessage.replace(regex, (matchSubString: string, index: number) => {
      const beforeSubString = this.parsedMessage.slice(0, index);

      if (this.shouldBeWrapped(beforeSubString, entityType)) {
        return this.getWrappedText({ ...entity, text: matchSubString }, attachId);
      }

      return matchSubString;
    });

    if (attachId) {
      this.parsedEntities[attachId] = entity;
    }
  }

  private attachEntities() {
    const sortedEntities = [...this.entities].sort((a, b) => b.text.length - a.text.length);

    sortedEntities.forEach((entity) => this.attachEntity(entity));
    return this;
  }

  private getParsedResult() {
    // clear temporary parsing wrappers
    const message = this.sanitize(this.parsedMessage.replaceAll('</>', ''));
    const entities = this.parsedEntities;
    this.reset();
    return [message, entities] as ParseResponse;
  }

  parse(
    originalMessage: Transcription['message'],
    { aliases = {}, search = '', isCluster }: ParseOptions = {}
  ): ParseResponse {
    const { categories = [], tags = [], locations = [], factions = [], callSigns = [], codes = [] } = aliases;

    try {
      return this.prepareMessage(originalMessage, isCluster)
        .prepareCategories(categories, tags)
        .prepareLocations(locations)
        .prepareFactions(factions)
        .prepareCallSigns(callSigns)
        .prepareCodes(codes)
        .prepareSearchEntities(search)
        .attachEntities()
        .getParsedResult();
    } catch (err) {
      console.error('[Parsing transcript message]', err);
      this.reset();
      return [this.sanitize(originalMessage), this.parsedEntities];
    }
  }
}

export default new TranscriptMessageService(DOMPurify);
