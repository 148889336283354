import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getFoxFrequencies } from '@/entities/source/@fox/api';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { ALL_FOX_FREQUENCIES_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options?: Omit<UseQueryOptions<ResponseData<FoxFrequency[]>, unknown, unknown, string[]>, 'queryKey'>;
}

const useGetAllDigitalFoxFrequencies = ({ sourceId, options }: Params) => {
  const { data, isFetching } = useQuery({
    ...options,
    queryKey: [ALL_FOX_FREQUENCIES_QUERY_KEY, sourceId],
    queryFn: () => getFoxFrequencies({ sourceId }),
    select: (originalData) => {
      return {
        ...originalData,
        models: originalData.data.filter((frequency) => frequency.isDigital),
      };
    },
  });

  return {
    foxFrequencies: data?.data ?? [],
    isAllFoxFrequenciesFetching: isFetching,
  };
};

export default useGetAllDigitalFoxFrequencies;
