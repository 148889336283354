import { z } from 'zod';
import { RoleName } from '@/shared/constants';
import {
  responseDataSchema,
  paginatedWithFoffsetSchema,
  sourceTypeSchema,
  foxStatusSchema,
} from '@/shared/validationSchemas';

export const baseUnitSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  fullName: z.string(),
  fullShortName: z.string(),
  parentId: z.string().nullable(),
});

type Unit = z.infer<typeof baseUnitSchema> & {
  children?: Unit[];
};

export const unitSchema: z.ZodType<Unit> = baseUnitSchema.and(
  z.object({
    children: z.lazy(() => unitSchema.array()).optional(),
  })
);

export const unitDetailsSchema = unitSchema.and(
  z.object({
    area: z.array(z.string()),
  })
);

export const unitUserSchema = z.object({
  id: z.number(),
  phone: z.string(),
  fullName: z.string().nullish(),
  role: z.nativeEnum(RoleName),
  isActive: z.boolean(),
  groupName: z.string().nullish(),
  groupId: z.string().nullish(),
  unitId: z.string().nullish(),
  unitName: z.string().nullish(),
});

export const unitSourceSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: sourceTypeSchema,
  status: foxStatusSchema.nullish(),
  groupId: z.string(),
  groupName: z.string(),
  externalId: z.string(),
  unitId: z.string().nullable(),
  createdAt: z.number(),
});

export const getUnitsSchema = responseDataSchema(z.array(unitSchema));

export const getUnitDetailsSchema = responseDataSchema(unitDetailsSchema);

export const createUnitSchema = responseDataSchema(unitSchema);

export const updateUnitSchema = responseDataSchema(unitSchema);

export const getFreeUsersSchema = paginatedWithFoffsetSchema(unitUserSchema);

export const getUnitUsersSchema = paginatedWithFoffsetSchema(unitUserSchema);

export const activateUnitUserSchema = responseDataSchema(unitUserSchema);

export const deactivateUnitUserSchema = responseDataSchema(unitUserSchema);

export const updateUnitUserSchema = responseDataSchema(unitUserSchema);

export const getUnitSourcesSchema = paginatedWithFoffsetSchema(unitSourceSchema);

export const updateUnitAreaSchema = responseDataSchema(z.object({}));
