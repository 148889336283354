import { PropsWithChildren } from 'react';
import {
  DecibelAxis,
  VerticalDashedElement,
  Frequencies,
  OverlaySpin,
  ActionButtons,
  FrequenciesAxes,
  HoveredFrequency,
  Detections,
  NewFrequency,
  Status,
  AnchorGrid,
} from './components';
import { CANVAS_ID, TOP_FREQUENCIES_AXIS_ID } from './constants';
import { useSpectrogram } from './hooks';
import styles from './styles.module.css';
import { SpectrogramProps as Props } from './types';

const SPECTRUM_PERCENT = 25;
const HOVERED_FREQUENCY_TOP_STYLE = `calc(${SPECTRUM_PERCENT}% - 5px)`;
const DECIBEL_AXIS_BOTTOM_STYLE = `calc(${100 - SPECTRUM_PERCENT}% - 10px)`;
const ANCHOR_GRID_GAP = 0.005;

const Spectrogram = ({
  decibelPower,
  startFrequency,
  endFrequency,
  minDecibel,
  maxDecibel,
  detections,
  detectionsVisibilityRange,
  memoFrequencies,
  memoOnEditFrequency,
  memoOnDeleteFrequency,
  memoOnFrequencyClick,
  onCreateFrequency,
  status,
  memoSelectedFrequencies,
  memoOnFrequenciesSelect,
  isLoading,
  children,
}: PropsWithChildren<Props>) => {
  const {
    canvasRect,
    spectrogramStatus,
    isSpectrogramDrawing,
    zoomData,
    isLowestZoomLevel,
    memoHandleResetZoomData,
    cursorClickXPosition,
    canvasWidth,
    canvasRef,
    canvasWrapperRef,
    handleMouseDown,
    handleMouseUp,
    cursorXPosition,
    handleMouseMove,
    isSpinnerVisible,
    canvasZoomStyle,
    currentStartFrequency,
    currentEndFrequency,
    memoHandleCreateFrequency,
    memoDetectionFrequencies,
    isFullScreenEnabled,
    isMouseDownOnCanvas,
    memoToggleFullScreen,
    areDetectionsVisible,
    memoToggleDetectionsVisibility,
    isAnyFrequencySelected,
    memoHandleFrequencyClick,
    isMouseDownOnWaterfall,
    canAnchorGridBeVisible,
  } = useSpectrogram({
    decibelPower,
    canvasId: CANVAS_ID,
    spectrumPercent: SPECTRUM_PERCENT,
    startFrequency,
    endFrequency,
    minDecibel,
    maxDecibel,
    onCreateFrequency,
    memoFrequencies,
    status,
    memoOnFrequencyClick,
    memoSelectedFrequencies,
    memoOnFrequenciesSelect,
    isLoading,
  });

  return (
    <div
      className={styles.wrapper}
      ref={canvasWrapperRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseUp}
    >
      <div className={styles.overflowHiddenWrapper}>
        <canvas
          id={CANVAS_ID}
          className={styles.canvas}
          ref={canvasRef}
          style={canvasZoomStyle}
        />
        <HoveredFrequency
          cursorPosition={cursorXPosition}
          canvasWidth={canvasWidth}
          top={HOVERED_FREQUENCY_TOP_STYLE}
          startFrequency={currentStartFrequency}
          endFrequency={currentEndFrequency}
        />
        {isSpectrogramDrawing && (
          <>
            <Frequencies
              maxLeftPosition={canvasRect.left}
              maxRightPosition={canvasRect.right}
              memoFrequencies={memoFrequencies}
              startFrequency={currentStartFrequency}
              endFrequency={currentEndFrequency}
              frequencyHeight={SPECTRUM_PERCENT}
              memoOnDeleteFrequency={memoOnDeleteFrequency}
              memoOnEditFrequency={memoOnEditFrequency}
              memoOnFrequencyClick={memoHandleFrequencyClick}
              memoSelectedFrequenciesDictionary={memoSelectedFrequencies}
            />
            {areDetectionsVisible && (
              <Detections
                topPosition={SPECTRUM_PERCENT}
                startFrequency={currentStartFrequency}
                endFrequency={currentEndFrequency}
                detections={detections}
                detectionsVisibilityRange={detectionsVisibilityRange}
                memoFrequencies={memoDetectionFrequencies}
                memoOnCreateFrequency={memoHandleCreateFrequency}
              />
            )}
            <NewFrequency
              isAddingNewFrequencyEnabled={!isAnyFrequencySelected}
              maxLeftPosition={canvasRect.left}
              maxRightPosition={canvasRect.right}
              memoFrequencies={memoFrequencies}
              isMouseDownOnCanvas={isMouseDownOnCanvas}
              canvasWidth={canvasWidth}
              startFrequency={currentStartFrequency}
              endFrequency={currentEndFrequency}
              memoFrequencyPosition={cursorClickXPosition}
              memoOnCreateFrequency={memoHandleCreateFrequency}
              frequencyHeight={SPECTRUM_PERCENT}
            />
            <AnchorGrid
              canBeVisible={canAnchorGridBeVisible}
              gap={ANCHOR_GRID_GAP}
              startFrequency={currentStartFrequency}
              endFrequency={currentEndFrequency}
              topPosition={SPECTRUM_PERCENT}
              wrapperWidth={canvasWidth}
            />
          </>
        )}
      </div>
      <Status status={spectrogramStatus} />
      <FrequenciesAxes
        startPoint={currentStartFrequency}
        endPoint={currentEndFrequency}
        topFrequenciesAxisId={TOP_FREQUENCIES_AXIS_ID}
        topPosition={SPECTRUM_PERCENT}
      />
      <DecibelAxis
        minDecibel={minDecibel}
        maxDecibel={maxDecibel}
        bottom={DECIBEL_AXIS_BOTTOM_STYLE}
      />
      <VerticalDashedElement
        canvasWidth={canvasWidth}
        cursorPosition={cursorXPosition}
        cursorDownXPosition={cursorClickXPosition.down}
        isElementExpandable={!isLowestZoomLevel}
        spectrumPercent={SPECTRUM_PERCENT}
        isMouseDownOnWaterfall={isMouseDownOnWaterfall}
      />
      {isSpectrogramDrawing && (
        <ActionButtons
          isResetZoomButtonVisible={!!zoomData}
          memoOnResetZoomButtonClick={memoHandleResetZoomData}
          isFullScreenEnabled={isFullScreenEnabled}
          memoOnFullScreenButtonClick={memoToggleFullScreen}
          isDetectionsButtonActive={areDetectionsVisible}
          memoOnDetectionsButtonClick={memoToggleDetectionsVisibility}
        />
      )}
      <OverlaySpin isVisible={isSpinnerVisible} />
      {children}
    </div>
  );
};

export default Spectrogram;
