import { useEffect, useRef } from 'react';
import { useFullScreen, useHotkeyListener, useIsMobile } from '@/shared/hooks';
import styles from '../styles.module.css';

type Params = {
  isHotkeysEnabled: boolean;
};

const useMapFullScreen = ({ isHotkeysEnabled }: Params) => {
  // we must use the body as the element for fullscreen mode here
  // to allow display all standalone Antd components rendered directly in the body
  // for example (notifications / tooltips / dropdowns)
  const documentBodyRef = useRef(document.body);
  const { isFullScreenEnabled, memoToggleFullScreen } = useFullScreen({ ref: documentBodyRef });
  const isMobile = useIsMobile();

  useHotkeyListener({
    hotkey: 'F',
    memoHotkeyUp: memoToggleFullScreen,
    memoIsSilent: !isHotkeysEnabled || isMobile,
  });

  useEffect(() => {
    // to be sure that all sidebars are hidden in fullscreen mode
    document.body.querySelectorAll('aside').forEach((elem) => {
      if (isFullScreenEnabled) {
        elem.classList.add(styles.hiddenSidebar);
      } else {
        elem.classList.remove(styles.hiddenSidebar);
      }
    });
  }, [isFullScreenEnabled]);

  return {
    isFullScreenEnabled,
  };
};

export default useMapFullScreen;
