import { useEffect, useRef } from 'react';
import { File } from '@/entities/source/@fox/types';
import { FOX_KEYS_QUERY_KEY } from '@/shared/constants';
import { useDeleteInfiniteItem, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';

const useDeleteRecordFromCache = () => {
  const { sourceId, tabEntityId } = useParams<SourceRouteParams>();
  const deleteRecordIdFromCache = useRef<null | string>(null);
  const deleteRecordFromCache = useDeleteInfiniteItem<File>([FOX_KEYS_QUERY_KEY, sourceId]);

  const setDeleteRecordIdFromCache = (id: string) => {
    deleteRecordIdFromCache.current = id;
  };

  useEffect(() => {
    if (!deleteRecordIdFromCache.current) return;

    deleteRecordFromCache('id', deleteRecordIdFromCache.current);
    deleteRecordIdFromCache.current = null;
  }, [tabEntityId]);

  return {
    setDeleteRecordIdFromCache,
  };
};

export default useDeleteRecordFromCache;
