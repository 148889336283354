import { useMemo } from 'react';
import { useDebounce } from '@reactuses/core';
import { RadioNetworkType, SortType, TranscriptQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import { SharedTranscriptsProps } from '@/shared/types';
import useParsedDateRange from './useParsedDateRange';

const FILTER_DEBOUNCE = 1200;

enum RequestQueryParam {
  Search = 'search',
  Order = 'order',
  Location = 'location[]',
  Faction = 'faction[]',
  Category = 'category[]',
  Frequency = 'frequencyValue[]',
  Source = 'source[]',
  Group = 'group[]',
  ProcessingStatus = 'processingStatus[]',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
}

interface Params extends SharedTranscriptsProps {}

const useTranscriptQueryParams = ({ networkId: radioNetworkId, networkType: radioNetworkType }: Params = {}) => {
  const [order] = useSearchParam<SortType>(TranscriptQueryParam.Order);
  const [search] = useSearchParam(TranscriptQueryParam.Search);
  const [location] = useSearchParam(TranscriptQueryParam.Location);
  const [faction] = useSearchParam(TranscriptQueryParam.Faction);
  const [category] = useSearchParam(TranscriptQueryParam.Category);
  const [frequency] = useSearchParam(TranscriptQueryParam.Frequency);
  const [source] = useSearchParam(TranscriptQueryParam.Source);
  const [group] = useSearchParam(TranscriptQueryParam.Group);
  const [processingStatus] = useSearchParam(TranscriptQueryParam.ProcessingStatus);
  const [dateRange] = useSearchParam(TranscriptQueryParam.DateRange);

  const [dateFrom, dateTo] = useParsedDateRange({ radioNetworkId, dateRange });

  const filterQueryParams = useMemo(
    () => ({ location, faction, category, frequency, source, group, processingStatus, dateFrom, dateTo }),
    [location, faction, category, frequency, source, group, processingStatus, dateFrom, dateTo]
  );
  const debouncedFilterQueryParams = useDebounce(filterQueryParams, FILTER_DEBOUNCE);

  const queryParams = useMemo(() => {
    return new URLSearchParams([
      [RequestQueryParam.Order, order || SortType.Descending],
      ...(search ? [[RequestQueryParam.Search, search]] : []),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.location)
        .map((item) => [RequestQueryParam.Location, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.faction)
        .map((item) => [RequestQueryParam.Faction, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.category)
        .map((item) => [RequestQueryParam.Category, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.frequency)
        .map((item) => [RequestQueryParam.Frequency, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.source)
        .map((item) => [RequestQueryParam.Source, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.group)
        .map((item) => [RequestQueryParam.Group, item]),
      ...queryParamsService
        .convertQueryParamToArray(debouncedFilterQueryParams.processingStatus)
        .map((item) => [RequestQueryParam.ProcessingStatus, item]),
      ...(debouncedFilterQueryParams.dateFrom
        ? [[RequestQueryParam.DateFrom, debouncedFilterQueryParams.dateFrom]]
        : []),
      ...(debouncedFilterQueryParams.dateTo ? [[RequestQueryParam.DateTo, debouncedFilterQueryParams.dateTo]] : []),
    ]).toString();
  }, [order, search, debouncedFilterQueryParams]);

  const radioNetworkParams = useMemo(() => {
    const frequencyId = radioNetworkId && radioNetworkType === RadioNetworkType.FREQUENCY ? [radioNetworkId] : null;
    const networkId = radioNetworkId && radioNetworkType === RadioNetworkType.NETWORK ? [radioNetworkId] : null;

    return {
      frequency: frequencyId,
      network: networkId,
    };
  }, [radioNetworkId, radioNetworkType]);

  const queryParamsWithRadioNetworkId = queryParamsService.extendByExtra(
    { queryParams, extra: radioNetworkParams },
    { checkPagination: false }
  );

  return {
    order,
    search,
    location,
    faction,
    category,
    frequency,
    source,
    group,
    processingStatus,
    dateRange,
    dateFrom,
    dateTo,
    queryParams: queryParamsWithRadioNetworkId,
  };
};

export default useTranscriptQueryParams;
