import { RefObject, useEffect, useState } from 'react';
import { useContentSize } from '@/shared/hooks';

type Params = {
  providerWrapperRef: RefObject<HTMLDivElement>;
};

const useMapVisibility = ({ providerWrapperRef }: Params) => {
  const [isVisible, setIsVisible] = useState(true);
  const { contentWidth } = useContentSize(providerWrapperRef);

  useEffect(() => {
    setIsVisible(contentWidth > 0);
  }, [contentWidth]);

  return isVisible;
};

export default useMapVisibility;
