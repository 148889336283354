export const NETWORKS_QUERY_PARAMS_KEY = 'networksQueryParams';
export const NETWORKS_TRANSCRIPTS_QUERY_PARAMS_KEY = 'networksTranscriptsQueryParams';

export enum NetworkQueryParam {
  Search = 'n_s',
  Location = 'n_l',
  Faction = 'n_f',
  Category = 'n_c',
  Group = 'n_g',
  DateRange = 'n_d',
}

export enum RadioNetworkTab {
  Interceptions = 'interceptions',
  Details = 'details',
  CallSigns = 'callsigns',
  Codes = 'codes',
  Journal = 'journal',
}
