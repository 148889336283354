import { KeyboardEvent } from 'react';
import { Form } from 'antd';
import { Key } from 'ts-key-enum';
import { useCreateFoxFrequencyFormSubmit } from '@/entities/source/@fox/components/ScanningTab/hooks';
import {
  getNormalizedNewFrequency,
  normalizeNewFrequencyFieldValue,
} from '@/entities/source/@fox/components/ScanningTab/utils';
import { CreateFoxFrequencyPayload } from '@/entities/source/@fox/types';
import { useSource } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';

type Params = {
  onCloseForm: VoidFunction;
};

const useCreateFoxFrequencyForm = ({ onCloseForm }: Params) => {
  const [form] = Form.useForm<CreateFoxFrequencyPayload>();
  const source = useSource<FoxSource>();

  const { handleFinish, isFoxFrequencyCreating } = useCreateFoxFrequencyFormSubmit({
    form,
    onCancelAddNewFoxFrequency: onCloseForm,
  });

  const handleCancel = () => {
    form.resetFields();
    onCloseForm();
  };

  const handleValueInputKeyDown = (e: KeyboardEvent) => {
    if (e.key === Key.Enter) {
      normalizeNewFrequencyFieldValue({ form, getNormalizedNewFrequency });
    }
  };

  const handleValueInputBlur = () => {
    normalizeNewFrequencyFieldValue({ form, getNormalizedNewFrequency });
  };

  return {
    form,
    isFoxFrequencyCreating,
    frequencyRange: source?.frequencyRange,
    handleFinish,
    handleCancel,
    handleValueInputBlur,
    handleValueInputKeyDown,
  };
};

export default useCreateFoxFrequencyForm;
