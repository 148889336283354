import { useLocation, Outlet } from 'react-router';
import { Form, Layout, Row, Col, Typography } from 'antd';
import { GoogleAuthenticatorIcon } from '@/shared/assets';
import { TechSupportFooter } from '@/shared/components/TechSupportFooter';
import {
  COPIES,
  PHONE_CONFIRMATION_PATH,
  AUTHENTICATOR_SETUP_PATH,
  GOOGLE_AUTH_CONFIRMATION_PATH,
} from '@/shared/constants';
import styles from './styles.module.css';

interface Props {
  title: string;
}

export const LayoutAuth = <T,>({ title }: Props) => {
  const [form] = Form.useForm<T>();
  const location = useLocation();
  const isPhoneConfirmation = location.pathname.includes(PHONE_CONFIRMATION_PATH);
  const isAuthenticatorSetup = location.pathname.includes(AUTHENTICATOR_SETUP_PATH);
  const isGoogleAuthConfirmation = location.pathname.includes(GOOGLE_AUTH_CONFIRMATION_PATH);

  const getTitle = () => {
    if (isPhoneConfirmation) {
      return COPIES.PHONE_VERIFICATION;
    }
    if (isAuthenticatorSetup) {
      return COPIES.SCAN_QR_CODE;
    }
    if (isGoogleAuthConfirmation) {
      return COPIES.TWO_FACTOR_AUTH;
    }
    return title;
  };

  return (
    <Layout.Content className={styles.layout}>
      <Row className={styles.layoutContent}>
        <Col span={24}>
          {isAuthenticatorSetup && (
            <Row justify="center">
              <Col>
                <GoogleAuthenticatorIcon />
              </Col>
            </Row>
          )}
          <Row>
            <Col
              className={styles.layoutTitleWrapper}
              span={24}
            >
              <Typography.Title
                className={styles.layoutTitle}
                level={3}
                data-testid="auth-title"
              >
                {getTitle()}
              </Typography.Title>
            </Col>
          </Row>
          <Outlet context={{ form }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TechSupportFooter />
        </Col>
      </Row>
    </Layout.Content>
  );
};
