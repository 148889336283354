import { KeyboardEvent } from 'react';
import { Form } from 'antd';
import { Key } from 'ts-key-enum';
import { FORM_INITIAL_VALUES } from '@/entities/source/@fox/components/ScanningTab/constants';
import { useCreateFoxFrequencyFormSubmit } from '@/entities/source/@fox/components/ScanningTab/hooks';
import {
  getNormalizedNewFrequency,
  normalizeNewFrequencyFieldValue,
} from '@/entities/source/@fox/components/ScanningTab/utils';
import { CreateFoxFrequencyPayload } from '@/entities/source/@fox/types';
import { useSource } from '@/entities/source/hooks';
import { FoxSource } from '@/entities/source/types';

interface Params {
  onCancelAddNewFoxFrequency: () => void;
}

const useAddFrequencyRow = ({ onCancelAddNewFoxFrequency }: Params) => {
  const source = useSource<FoxSource>();
  const [form] = Form.useForm<CreateFoxFrequencyPayload>();

  const handleCancelAddNewFoxFrequency = () => {
    form.resetFields();
    onCancelAddNewFoxFrequency();
  };

  const { handleFinish } = useCreateFoxFrequencyFormSubmit({
    form,
    onCancelAddNewFoxFrequency: handleCancelAddNewFoxFrequency,
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === Key.Escape) {
      handleCancelAddNewFoxFrequency();
    }
    if (e.key === Key.Enter) {
      normalizeNewFrequencyFieldValue({ form, getNormalizedNewFrequency });
    }
  };

  const handleValueInputBlur = () => {
    normalizeNewFrequencyFieldValue({ form, getNormalizedNewFrequency });
  };

  return {
    form,
    source,
    handleFinish,
    handleKeyDown,
    handleValueInputBlur,
    initialValues: FORM_INITIAL_VALUES,
  };
};

export default useAddFrequencyRow;
