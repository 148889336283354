import {
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { FrequencyIcon } from '@/shared/assets';
import { Tag } from '@/shared/components/ui/Tag';
import { ADMIN_ROLES, Page, SUPERVISOR_ROLES, TECHNOLOGIST_ROLES } from '@/shared/constants';
import HotkeyRow from '../HotkeyRow';
import HotkeysLayout from '../HotkeysLayout';
import { SEARCH_LABEL, THEN } from './constants';
import styles from './styles.module.css';

const NavigationHotkeys = () => {
  return (
    <HotkeysLayout
      firstColumn={
        <>
          <HotkeyRow
            icon={AppstoreOutlined}
            label={Page.Overview}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>O</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={FrequencyIcon}
            label={Page.AllInterceptions}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>I</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={DeploymentUnitOutlined}
            label={Page.RadioNetworks}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>N</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={ApiOutlined}
            label={Page.Sources}
            memoAvailableForRoles={TECHNOLOGIST_ROLES}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>S</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={ApartmentOutlined}
            iconClassName={styles.factionIcon}
            label={Page.Factions}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>F</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={TagOutlined}
            label={Page.Categories}
            memoAvailableForRoles={ADMIN_ROLES}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>C</Tag>
          </HotkeyRow>
        </>
      }
      secondColumn={
        <>
          <HotkeyRow
            icon={TeamOutlined}
            label={Page.AccessGroups}
            memoAvailableForRoles={SUPERVISOR_ROLES}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>A</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={PlusSquareOutlined}
            label={Page.Units}
            memoAvailableForRoles={ADMIN_ROLES}
          >
            <Tag>G</Tag>
            {THEN}
            <Tag>U</Tag>
          </HotkeyRow>
          <HotkeyRow
            icon={SearchOutlined}
            label={SEARCH_LABEL}
          >
            <Tag>/</Tag>
          </HotkeyRow>
        </>
      }
    />
  );
};

export default NavigationHotkeys;
