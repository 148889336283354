import { useMutation, MutateOptions } from '@tanstack/react-query';
import {
  deactivateFoxFrequency as deactivateFoxFrequencyApi,
  DeactivateFoxFrequencyParams,
} from '@/entities/source/@fox/api';
import { DeactivateFoxFrequency } from '@/entities/source/@fox/types';
import { DEACTIVATE_FOX_FREQUENCY_MUTATION_KEY } from '@/shared/constants';

const useDeactivateFoxFrequency = (
  options: MutateOptions<DeactivateFoxFrequency, unknown, DeactivateFoxFrequencyParams> = {}
) => {
  const { mutate: deactivateFoxFrequency, isPending: isFoxFrequencyDeactivating } = useMutation({
    ...options,
    mutationKey: [DEACTIVATE_FOX_FREQUENCY_MUTATION_KEY],
    mutationFn: deactivateFoxFrequencyApi,
  });

  return {
    deactivateFoxFrequency,
    isFoxFrequencyDeactivating,
  };
};

export default useDeactivateFoxFrequency;
