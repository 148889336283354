import { Layout, Flex, Typography, Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { ErrorIcon, ServiceUnavailableIcon } from '@/shared/assets';
import { TechSupportFooter } from '@/shared/components/TechSupportFooter';
import { sentryService } from '@/shared/services';
import styles from './styles.module.css';

const TITLE_UNAVAILABLE = 'Сервіс недоступний';
const DESCRIPTION_UNAVAILABLE = 'Ми працюємо над тим щоб усунути цю помилку. Спробуйте оновити через кілька хвилин.';
const TITLE_ERROR = 'Невідома помилка';
const DESCRIPTION_ERROR = 'Ми працюємо над тим щоб усунути цю помилку. Спробуйте оновити сторінку.';
const REFRESH_LABEL = 'Оновити сторінку';

type Props = {
  error: unknown;
  isUnavailable?: boolean;
  resetError?: () => void;
};

export const ErrorCrash = ({ isUnavailable, resetError, error }: Props) => {
  const handleRefresh = () => {
    if (error) {
      sentryService.captureExpandedException({
        exception: error,
        extraData: {
          name: 'refresh page button pressed on page',
          value: window.location.href,
        },
      });
    }
    resetError?.();
    window.location.reload();
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Content className={styles.errorLayout}>
        <Flex
          vertical
          align="center"
          className={styles.errorContent}
        >
          {isUnavailable ? (
            <ServiceUnavailableIcon className={styles.errorIcon} />
          ) : (
            <ErrorIcon className={styles.errorIcon} />
          )}
          <Typography.Title
            className={styles.errorTitle}
            level={2}
          >
            {isUnavailable ? TITLE_UNAVAILABLE : TITLE_ERROR}
          </Typography.Title>
          <Typography.Paragraph className={styles.errorDescription}>
            {isUnavailable ? DESCRIPTION_UNAVAILABLE : DESCRIPTION_ERROR}
          </Typography.Paragraph>
          <Button
            className={styles.refreshButton}
            type="primary"
            size="large"
            icon={<RedoOutlined />}
            onClick={handleRefresh}
          >
            {REFRESH_LABEL}
          </Button>
        </Flex>
      </Layout.Content>
      <TechSupportFooter />
    </Layout>
  );
};
