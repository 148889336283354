import { CreateSourcePayload, EditSource, Source, SourceListItem, UpdateSourceArea } from '@/entities/source/types';
import { PaginatedWithFoffset, request, RequestQueryParams, ResponseData } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { FiltersSource } from '@/shared/types';
import { UpdateSourceAreaParams, UpdateSourceParams } from './types';
import {
  createSourceSchema,
  getFilterSourcesSchema,
  getSourceByIdSchema,
  getSourcesSchema,
  updateSourceAreaSchema,
  updateSourceSchema,
} from './validationSchemas';

export const getSources = ({ queryParams }: { queryParams: RequestQueryParams }) => {
  return request<PaginatedWithFoffset<SourceListItem>>('/sources', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getSourcesSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });
};

export const getSourceById = <T = Source>(sourceId: string) => {
  return request<ResponseData<T>>(`/sources/${sourceId}`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getSourceByIdSchema,
  });
};

export const createSource = (payload: CreateSourcePayload) => {
  return request<ResponseData<Source>>('/sources', {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: createSourceSchema,
  });
};

export const updateSourceArea = ({ sourceId, payload }: UpdateSourceAreaParams) => {
  return request<UpdateSourceArea>(`/sources/${sourceId}/area`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: updateSourceAreaSchema,
  });
};

export const getFilterSources = (queryParams: RequestQueryParams) => {
  return request<PaginatedWithFoffset<FiltersSource>>(`/transcripts/filter/sources`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFilterSourcesSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });
};

export const updateSource = ({ sourceId, payload }: UpdateSourceParams) =>
  request<ResponseData<EditSource>>(`/sources/${sourceId}`, {
    endpointPrefix: V3_PREFIX,
    method: 'PATCH',
    body: JSON.stringify(payload),
    validationSchema: updateSourceSchema,
  });
