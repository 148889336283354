import { useMemo } from 'react';
import { AnchorGridProps } from '../types';
import { getRoundedStartFrequency, getFrequenciesList } from '../utils';

const MIN_DISTANCE_BETWEEN_LINES = 10;

interface Params extends Pick<AnchorGridProps, 'startFrequency' | 'endFrequency' | 'gap' | 'wrapperWidth'> {}

const useAnchorGrid = ({ startFrequency, endFrequency, gap, wrapperWidth }: Params) => {
  const firstLineFrequency = useMemo(() => {
    return getRoundedStartFrequency({ startFrequency, roundTo: gap });
  }, [gap, startFrequency]);

  const lineFrequenciesList = useMemo(() => {
    return getFrequenciesList({ endFrequency, firstLineFrequency, gap });
  }, [endFrequency, firstLineFrequency, gap]);

  const shouldBeVisible = wrapperWidth / lineFrequenciesList.length >= MIN_DISTANCE_BETWEEN_LINES;

  return {
    lineFrequenciesList,
    shouldBeVisible,
  };
};

export default useAnchorGrid;
