const getNormalizedNewFrequency = (frequency: number): number => {
  const frequencyString = frequency.toFixed(4);
  const [integerPart, decimalPart] = frequencyString.split('.');
  let integerFrequency = parseInt(integerPart, 10);
  let firstDecimal = parseInt(decimalPart[0], 10);
  let secondDecimal = parseInt(decimalPart[1], 10);
  let thirdDecimal = parseInt(decimalPart[2], 10);
  let foursDecimal = parseInt(decimalPart[3], 10);
  const lastTwoDecimals = thirdDecimal * 10 + foursDecimal;

  if (lastTwoDecimals === 0 || lastTwoDecimals === 50) {
    return frequency;
  }

  if (lastTwoDecimals < 75) {
    thirdDecimal = 5;
    foursDecimal = 0;
    const newStr = `${integerFrequency}.${firstDecimal}${secondDecimal}${thirdDecimal}${foursDecimal}`;
    return parseFloat(newStr);
  }

  secondDecimal++;
  if (secondDecimal === 10) {
    secondDecimal = 0;
    firstDecimal++;
    if (firstDecimal === 10) {
      firstDecimal = 0;
      integerFrequency++;
    }
  }
  thirdDecimal = 0;
  foursDecimal = 0;
  const finalStr = `${integerFrequency}.${firstDecimal}${secondDecimal}${thirdDecimal}${foursDecimal}`;
  return parseFloat(finalStr);
};

export default getNormalizedNewFrequency;
