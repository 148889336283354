import { useAppSelector } from '@/shared/hooks';
import { selectShouldRedirectToPageNotFound, selectShouldRedirectToPageAccessDenied } from '@/shared/slices';
import useAccessDeniedRedirect from './useAccessDeniedRedirect';
import usePartiallyVisibleDataNotification from './usePartiallyVisibleDataNotification';

const useAccessRouteGuard = () => {
  const shouldRedirectToPageNotFound = useAppSelector(selectShouldRedirectToPageNotFound);
  const shouldRedirectToPageAccessDenied = useAppSelector(selectShouldRedirectToPageAccessDenied);
  const isRedirectDataInitialized = shouldRedirectToPageAccessDenied !== null;

  useAccessDeniedRedirect();

  usePartiallyVisibleDataNotification();

  return {
    isRedirectDataInitialized,
    shouldRedirectToPageNotFound,
    shouldRedirectToPageAccessDenied,
  };
};

export default useAccessRouteGuard;
