import { useState } from 'react';
import { getUnitSources } from '@/entities/unit/api';
import { UnitSource } from '@/entities/unit/types';
import { UNIT_SOURCES_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery, UseInfiniteListQueryOptions, useUpdateFoffset } from '@/shared/hooks';

interface Params {
  unitId: string;
  options?: Omit<UseInfiniteListQueryOptions<UnitSource>, 'queryKey' | 'initialPageParam'>;
}

const useGetUnitSources = ({ unitId, options = {} }: Params) => {
  const [foffset, setFoffset] = useState<string | null>(null);

  const {
    data,
    list: unitSourcesList,
    getListRef: getUnitSourcesRef,
    isLoading: isUnitSourcesLoading,
    isFetching: isUnitSourcesFetching,
    refetch: refetchUnitSources,
  } = useInfiniteListQuery({
    ...options,
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [UNIT_SOURCES_QUERY_KEY, unitId],
    queryFn: ({ pageParam }) => {
      return getUnitSources({
        queryParams: {
          page: pageParam as number,
          foffset: pageParam !== 1 ? foffset : null,
          unitId,
        },
      });
    },
    initialPageParam: 1,
  });

  useUpdateFoffset({ data, setFoffset });

  return {
    unitSourcesList,
    getUnitSourcesRef,
    isUnitSourcesLoading,
    isUnitSourcesFetching,
    refetchUnitSources,
  };
};

export default useGetUnitSources;
