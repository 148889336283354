import { ReactNode, useMemo } from 'react';
import { Col, Row } from 'antd';
import { useIsMobile, useIsTablet } from '@/shared/hooks';
import styles from './styles.module.css';

type Props = {
  firstColumn?: ReactNode;
  secondColumn?: ReactNode;
  thirdColumn?: ReactNode;
};

const HotkeysLayout = ({ firstColumn, secondColumn, thirdColumn }: Props) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const colSpan = useMemo(() => {
    if (isMobile) return 24;
    if (isTablet) return 12;
    return 8;
  }, [isMobile, isTablet]);

  return (
    <Row
      gutter={[80, 16]}
      wrap={isTablet || isMobile}
    >
      <Col
        className={styles.column}
        span={colSpan}
      >
        {firstColumn}
      </Col>
      <Col
        className={styles.column}
        span={colSpan}
      >
        {secondColumn}
      </Col>
      <Col
        className={styles.column}
        span={colSpan}
      >
        {thirdColumn}
      </Col>
    </Row>
  );
};

export default HotkeysLayout;
