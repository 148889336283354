import { FC } from 'react';
import { GetProps } from 'antd';
import AntdIcon from '@ant-design/icons';
import classNames from 'classnames';
import styles from './styles.module.css';

interface CustomIconComponentProps extends GetProps<typeof AntdIcon> {
  component: FC;
}

const Icon = ({ className, ...restProps }: Partial<CustomIconComponentProps>) => {
  return (
    <AntdIcon
      className={classNames(styles.icon, className)}
      {...restProps}
    />
  );
};

export default Icon;
