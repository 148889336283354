import { PropsWithChildren } from 'react';
import { QueryClient, QueryCache, MutationCache, QueryClientProvider as Provider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { errorHandlingService } from '@/shared/api';
import { STATUS_TYPES } from '@/shared/constants';
import validation from '@/shared/constants/validation.json';
import { useNotificationContext } from '@/shared/context';
import { useAppDispatch } from '@/shared/hooks';
import { v3Service } from '@/shared/services';
import { setServerAvailabilityCheckEnabled } from '@/shared/slices';
import { config } from './config';
import { showQueryNotification, showMutationNotification } from './utils';

const WITH_REACT_QUERY_DEV_TOOL = false;

const QueryClientProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { openNotification } = useNotificationContext();

  const queryClient = new QueryClient({
    defaultOptions: config,
    queryCache: new QueryCache({
      onError: async (err, query) => {
        const isServerError = errorHandlingService.isServerError(err);
        const isErrorCauseServerError = errorHandlingService.isErrorCauseServerError(err);

        if (isServerError || isErrorCauseServerError) {
          dispatch(setServerAvailabilityCheckEnabled(true));
        }

        if (v3Service.isError(err)) {
          v3Service.openErrorNotifications({
            error: err,
            title: STATUS_TYPES.ERROR,
            validation,
            openNotification,
          });
        } else if (isErrorCauseServerError && v3Service.isError(err.cause.originalError)) {
          v3Service.openErrorNotifications({
            error: err.cause.originalError,
            title: STATUS_TYPES.ERROR,
            validation,
            openNotification,
          });
        } else {
          showQueryNotification(err, query, openNotification);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (err, _variables, _context, mutation) => {
        const isServerError = errorHandlingService.isServerError(err);
        const isErrorCauseServerError = errorHandlingService.isErrorCauseServerError(err);

        if (isServerError || isErrorCauseServerError) {
          dispatch(setServerAvailabilityCheckEnabled(true));
        }

        if (v3Service.isError(err)) {
          v3Service.openErrorNotifications({
            error: err,
            title: STATUS_TYPES.ERROR,
            validation,
            openNotification,
          });
        } else if (isErrorCauseServerError && v3Service.isError(err.cause.originalError)) {
          v3Service.openErrorNotifications({
            error: err.cause.originalError,
            title: STATUS_TYPES.ERROR,
            validation,
            openNotification,
          });
        } else {
          showMutationNotification(err, mutation, openNotification);
        }
      },
    }),
  });
  return (
    <Provider client={queryClient}>
      {children}
      {WITH_REACT_QUERY_DEV_TOOL ? (
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom"
          buttonPosition="top-right"
        />
      ) : null}
    </Provider>
  );
};

export default QueryClientProvider;
