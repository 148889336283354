import { useNavigate as useNavigateReactRouter } from 'react-router';
import { QueryParamsOptions } from '@/shared/types';

export type NavigateParams = {
  pathname: string;
  options?: QueryParamsOptions;
};

export const useNavigate = () => {
  const navigate = useNavigateReactRouter();

  return (navParams: NavigateParams | string) => {
    const { searchParams } = new URL(document.location.toString());
    const { pathname, options } =
      typeof navParams === 'string' ? { pathname: navParams, options: undefined } : navParams;

    const { withSpecificQueryParam, withAllQueryParams, additionalQueryParams } = options || {};

    let reusedQueryParams: string = '';

    if (withSpecificQueryParam === 'string') {
      reusedQueryParams = searchParams.get(withSpecificQueryParam) || '';
    }

    if (withAllQueryParams) {
      reusedQueryParams = searchParams.toString();
    }

    const queryParams =
      additionalQueryParams && reusedQueryParams
        ? `${additionalQueryParams}&${reusedQueryParams}`
        : additionalQueryParams || reusedQueryParams;

    navigate({
      pathname,
      search: queryParams,
    });
  };
};
