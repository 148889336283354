import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { authService } from '@/shared/api';
import { ALLOWED_PAGES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  selectCurrentUserRole,
  selectShouldRedirectToPageAccessDenied,
  setShouldRedirectToPageAccessDenied,
} from '@/shared/slices';
import { roleHasAccess } from '../utils';

const useAccessDeniedRedirect = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentUserRole);
  const shouldRedirectToPageAccessDenied = useAppSelector(selectShouldRedirectToPageAccessDenied);

  useEffect(() => {
    const hasAuthData = Boolean(authService.getAuthDataFromLocalStorage());
    if (!hasAuthData) return;

    const isAccessAllowed = roleHasAccess({ role, pathname, allowedPages: ALLOWED_PAGES });
    if (isAccessAllowed && shouldRedirectToPageAccessDenied !== false) {
      dispatch(setShouldRedirectToPageAccessDenied(false));
    }
    if (!isAccessAllowed && shouldRedirectToPageAccessDenied !== true) {
      dispatch(setShouldRedirectToPageAccessDenied(true));
    }
  }, [dispatch, pathname, role, shouldRedirectToPageAccessDenied]);
};

export default useAccessDeniedRedirect;
