import { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';

export const PREVIOUS_MONTH = dayjs().add(-30, 'd').startOf('day');

export const RANGE_PRESETS: TimeRangePickerProps['presets'] = [
  { label: 'Сьогодні', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  { label: 'Вчора', value: [dayjs().add(-1, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')] },
  { label: 'Минулі 7 днів', value: [dayjs().add(-7, 'd').startOf('day'), dayjs().endOf('day')] },
  { label: 'Минулі 30 днів', value: [dayjs().add(-30, 'd').startOf('day'), dayjs().endOf('day')] },
  { label: 'Минулі 90 днів', value: [dayjs().add(-90, 'd').startOf('day'), dayjs().endOf('day')] },
  { label: 'Поточний рік', value: [dayjs().startOf('year'), dayjs().endOf('day')] },
  { label: 'Минулі 365 днів', value: [dayjs().add(-365, 'd').startOf('day'), dayjs().endOf('day')] },
];
