import { RefObject } from 'react';
import { ROW_HEIGHT } from '@/shared/components/FilterSelect/constants';
import { useContentSize } from '@/shared/hooks';

const useSelectListLoader = (resizableRef: RefObject<HTMLDivElement>) => {
  const { contentHeight } = useContentSize(resizableRef);
  const repeatTimes = Math.ceil(contentHeight / ROW_HEIGHT);

  const getTitleWidth = (index: number) => {
    return index % 2 === 0 ? '30%' : '40%';
  };

  return {
    repeatTimes,
    getTitleWidth,
  };
};

export default useSelectListLoader;
