import { useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ALL_FREQUENCIES_KEY } from '@/shared/constants';
import { Frequency } from '@/shared/types';
import { adaptV2ToV3RadioNetwork } from '@/shared/utils';
import { getFrequencies } from '../api';

type Options = {
  search?: string;
  notInNetworks?: boolean;
  ids?: string[];
  isEnabled?: boolean;
};

export const useGetFrequencies = (options: Options = {}) => {
  const { search = '', notInNetworks = false, ids = [], isEnabled = true } = options;

  const { data, isFetching, isLoading, isSuccess } = useQuery({
    queryKey: [ALL_FREQUENCIES_KEY, search, notInNetworks, ids],
    queryFn: () => getFrequencies(search, notInNetworks, ids),
    staleTime: Infinity,
    enabled: isEnabled,
    placeholderData: isEnabled ? keepPreviousData : undefined,
  });
  const list = useMemo(() => data?.data.map(adaptV2ToV3RadioNetwork<Frequency>), [data]);

  return {
    frequencies: list || [],
    isFrequenciesFetching: isFetching,
    isLoading,
    isFrequenciesFetchedSuccessfully: isSuccess,
  };
};
