import NavigationDrawer from '@/shared/components/NavigationDrawer';
import Tabs from '@/shared/components/ui/Tabs';
import { ItemKey } from './constants';
import styles from './styles.module.css';
import useHotkeysDrawer from './useHotkeysDrawer';

const HOTKEYS = 'Гарячі клавіші';

const HotkeysDrawer = () => {
  const { navigationDrawer, tabs } = useHotkeysDrawer();

  return (
    <NavigationDrawer
      className={styles.drawer}
      mobileClassName={styles.drawer}
      title={HOTKEYS}
      placement="bottom"
      open={navigationDrawer === 'hotkeys'}
    >
      <Tabs
        className={styles.tabs}
        defaultActiveKey={ItemKey.Navigation}
        items={tabs}
        renderDelay={0}
      />
    </NavigationDrawer>
  );
};

export default HotkeysDrawer;
