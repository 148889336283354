import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import 'maplibre-gl/dist/maplibre-gl.css';
import { MapContainerProps } from '@/entities/map/components/MapContainer/types';
import { MAP_COLORS, MapSource } from '@/entities/map/constants';
import { MaplibreMap } from '@/entities/map/types';
import { ContextMenuTrigger, ElevationLegend } from './components';
import styles from './styles.module.css';

const MAP_WATERMARK_Z_INDEX = 999;

interface Props extends Omit<MapContainerProps, 'mapOptions' | 'mapFooter' | 'isMapAvailable'> {
  mapContainerRefCallback: (node: HTMLDivElement | null) => void;
  map: MaplibreMap | null;
}

const MapLayout = ({
  mapContainerRefCallback,
  map,
  className,
  tileSource,
  renderWatermark,
  children,
}: PropsWithChildren<Props>) => {
  const shouldRenderWatermark = ![MapSource.CartoLight, MapSource.CartoDark].includes(tileSource);

  return (
    <div className={classnames(styles.mapWrapper, className)}>
      <ContextMenuTrigger map={map}>
        <div
          ref={mapContainerRefCallback}
          className={styles.mapContainer}
        />
      </ContextMenuTrigger>
      {tileSource === MapSource.Elevation && <ElevationLegend />}
      {children}
      {shouldRenderWatermark &&
        renderWatermark?.({
          color: MAP_COLORS.watermark,
          zIndex: MAP_WATERMARK_Z_INDEX,
        })}
    </div>
  );
};

export default MapLayout;
