import { matchRoutes, useLocation } from 'react-router';

type Route = {
  path: string;
};

const useMatchRoutes = (routes: Route[]) => {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  return Boolean(matches);
};

export default useMatchRoutes;
