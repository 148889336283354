import { PropsWithChildren } from 'react';
import { ErrorCrash } from '@/shared/components';
import { useAppSelector } from '@/shared/hooks';
import { selectServerAvailability, selectServerAvailabilityCheckEnabled } from '@/shared/slices';
import { useConnectionNotification, useSupportData } from './hooks';

const SupportWrapper = ({ children }: PropsWithChildren) => {
  const isServerAvailabilityCheckEnabled = useAppSelector(selectServerAvailabilityCheckEnabled);
  const isServerAvailable = useAppSelector(selectServerAvailability);

  useConnectionNotification();
  useSupportData(isServerAvailabilityCheckEnabled);

  return isServerAvailable ? (
    children
  ) : (
    <ErrorCrash
      isUnavailable
      error={null}
    />
  );
};

export default SupportWrapper;
