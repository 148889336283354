import { useMutation, MutateOptions } from '@tanstack/react-query';
import {
  activateFoxFrequencies as activateFoxFrequenciesApi,
  ActivateFoxFrequenciesParams,
} from '@/entities/source/@fox/api';
import { UpdateFoxFrequenciesStatus } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY } from '@/shared/constants';

const useActivateFoxFrequencies = (
  options: MutateOptions<ResponseData<UpdateFoxFrequenciesStatus>, unknown, ActivateFoxFrequenciesParams> = {}
) => {
  const { mutate: activateFoxFrequencies, isPending: isFoxFrequenciesActivating } = useMutation({
    ...options,
    mutationKey: [ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY],
    mutationFn: activateFoxFrequenciesApi,
  });

  return {
    activateFoxFrequencies,
    isFoxFrequenciesActivating,
  };
};

export default useActivateFoxFrequencies;
