import { Navigate } from 'react-router';
import { authService } from '@/shared/api';

type Props = {
  authorisedPath: string;
  notAuthorisedPath: string;
};

const RouteNotFound = ({ authorisedPath, notAuthorisedPath }: Props) => {
  const hasAuthData = Boolean(authService.getAuthDataFromLocalStorage());
  const redirectPath = hasAuthData ? authorisedPath : notAuthorisedPath;

  return (
    <Navigate
      to={redirectPath}
      replace
    />
  );
};

export default RouteNotFound;
