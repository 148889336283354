import { Flex } from 'antd';
import { ScrollUpToRefresh, VirtualList, LoadingWrapper } from '@/shared/components';
import { ListRow, ColumnsTitles } from './components';
import { useFoxFrequenciesTable } from './hooks';
import styles from './styles.module.css';
import { FoxFrequenciesTableProps } from './types';

const FoxFrequenciesTable = ({ highlightedFoxFrequencyId, scrollToRowIndex }: FoxFrequenciesTableProps) => {
  const {
    foxFrequencies,
    isLoadingIndicatorVisible,
    handleRowClick,
    handleScroll,
    handleScrollUpToReFetch,
    frequenciesListWrapperRef,
    handleListScroll,
    isOnTop,
    addFrequencyRow,
    addNewButton,
    isFrequenciesListEmpty,
    search,
  } = useFoxFrequenciesTable();

  return (
    <>
      {isFrequenciesListEmpty && (
        <>
          <Flex>{addNewButton}</Flex>
          {addFrequencyRow}
        </>
      )}
      <LoadingWrapper
        isLoading={isLoadingIndicatorVisible}
        isFixedOverlay
        isEmpty={isFrequenciesListEmpty}
      >
        <ScrollUpToRefresh
          className={styles.wrapper}
          childrenWrapperClassName={styles.childrenWrapper}
          isScrollUpToRefreshEnabled={foxFrequencies.length > 0}
          onRefresh={handleScrollUpToReFetch}
          onScroll={handleScroll}
          isRefreshTriggerDisabled={!isOnTop}
        >
          <VirtualList
            scrollToRowIndex={scrollToRowIndex}
            onScroll={handleListScroll}
            listRef={frequenciesListWrapperRef}
            data={foxFrequencies}
            defaultRowHeight={57}
            renderRow={(foxFrequency) => (
              <ListRow
                frequency={foxFrequency}
                highlightedFoxFrequencyId={highlightedFoxFrequencyId}
                onRowClick={handleRowClick}
                search={search}
              />
            )}
            header={
              <>
                {addNewButton}
                <ColumnsTitles />
                {addFrequencyRow}
              </>
            }
          />
        </ScrollUpToRefresh>
      </LoadingWrapper>
    </>
  );
};

export default FoxFrequenciesTable;
