import { MobileView } from '@/shared/constants';
import { useAppDispatch, useAppSelector, useIsTablet, useMobileActiveViewQueryParam } from '@/shared/hooks';
import {
  selectIsNavigationSidebarCollapsed,
  setIsNavigationSidebarCollapsed,
  setNavigationDrawer,
} from '@/shared/slices';

const useNavigationContent = () => {
  const isTablet = useIsTablet();
  const { setMobileActiveView } = useMobileActiveViewQueryParam();
  const isSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const dispatch = useAppDispatch();
  // Hide until new requirement
  const isSubscriptionHidden = true;

  const handleMenuItemClick = () => {
    setMobileActiveView?.(MobileView.PrimaryScreen, false);
    if (isTablet) dispatch(setIsNavigationSidebarCollapsed(true));
  };

  const handlePlaceholderClick = () => dispatch(setIsNavigationSidebarCollapsed(false));

  const handleHotkeysOpen = () => {
    dispatch(setNavigationDrawer('hotkeys'));
    handleMenuItemClick();
  };

  const handleSubscriptionOpen = () => {
    dispatch(setNavigationDrawer('subscription'));
    handleMenuItemClick();
  };

  const handleProfileOpen = () => {
    dispatch(setNavigationDrawer('profile'));
    handleMenuItemClick();
  };

  return {
    isTablet,
    isSidebarCollapsed,
    isSubscriptionHidden,
    handleMenuItemClick,
    handlePlaceholderClick,
    handleHotkeysOpen,
    handleSubscriptionOpen,
    handleProfileOpen,
  };
};

export default useNavigationContent;
