import { useMemo } from 'react';
import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { generateFoxWaterfallAuth } from '@/entities/source/@fox/api';
import { FoxWaterfallAuth } from '@/entities/source/@fox/types';
import { ResponseData } from '@/shared/api';
import { FOX_WATERFALL_AUTH_QUERY_KEY } from '@/shared/constants';

interface Params {
  sourceId: string;
  options: Omit<UseQueryOptions<unknown, unknown, ResponseData<FoxWaterfallAuth>, string[]>, 'queryKey'>;
}

const useGenerateFoxWaterfallAuth = ({ sourceId, options }: Params) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => {
    return [FOX_WATERFALL_AUTH_QUERY_KEY, sourceId];
  }, [sourceId]);

  const remove = () => {
    queryClient.removeQueries({ queryKey });
  };

  const { data } = useQuery({
    ...options,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: () => generateFoxWaterfallAuth(sourceId),
  });

  return {
    foxWaterfallAuth: data?.data,
    removeFoxWaterfallAuth: remove,
  };
};

export default useGenerateFoxWaterfallAuth;
