import { z } from 'zod';
import {
  baseSourceSchema,
  filtersSourceSchema,
  foxDeviceStatusSchema,
  foxFrequencyRangeSchema,
  paginatedWithFoffsetSchema,
  responseDataSchema,
  foxStatusSchema,
  sourceTypeSchema,
} from '@/shared/validationSchemas';

export const foxSourceSchema = baseSourceSchema.merge(
  z.object({
    type: sourceTypeSchema.extract(['fox']),
    status: foxStatusSchema,
    deviceStatus: foxDeviceStatusSchema,
    frequencyRange: foxFrequencyRangeSchema,
    firmwareVersion: z.string().nullish(),
    systemLoad: z.number(),
  })
);

const foxSourceListItemSchema = foxSourceSchema.omit({
  area: true,
  deviceStatus: true,
  frequencyRange: true,
  systemLoad: true,
  externalId: true,
});

export const notFoxSourceSchema = baseSourceSchema.merge(
  z.object({
    type: sourceTypeSchema.exclude(['fox']),
  })
);

const notFoxSourceListItemSchema = notFoxSourceSchema.omit({ area: true, externalId: true });

export const sourceSchema = foxSourceSchema.or(notFoxSourceSchema);

export const sourceListItemSchema = foxSourceListItemSchema.or(notFoxSourceListItemSchema);

export const getSourcesSchema = paginatedWithFoffsetSchema(sourceListItemSchema);

export const getSourceByIdSchema = responseDataSchema(sourceSchema);

export const getFilterSourcesSchema = paginatedWithFoffsetSchema(filtersSourceSchema);

export const createSourceSchema = responseDataSchema(sourceSchema);

export const updateSourceAreaSchema = responseDataSchema(z.object({}));

export const editSourceSchema = baseSourceSchema
  .omit({
    firstActiveAt: true,
    lastActiveAt: true,
    area: true,
  })
  .merge(
    z.object({
      status: foxStatusSchema.nullish(),
      groupName: z.string(),
      createdAt: z.number(),
    })
  );

export const updateSourceSchema = responseDataSchema(editSourceSchema);
