import { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import { Rule } from 'antd/es/form';
import { useQueryClient } from '@tanstack/react-query';
import { FoxKeyEncryption } from '@/entities/source/@fox/constants';
import { CreateFoxKeyFormField, CreateFoxKeyPayload } from '@/entities/source/@fox/types';
import { FOX_KEYS_QUERY_KEY } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useParams } from '@/shared/hooks';
import { validationService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import { normalizeFormItem } from '@/shared/utils';
import { getNormalizedFormValues, getFoxKeyBodyValidationRule, getFoxKeyGroupIdFrequencyRule } from '../utils';
import useCreateFoxKey from './useCreateFoxKey';
import useGetAllDigitalFoxFrequencies from './useGetAllDigitalFoxFrequencies';

const FORM_INITIAL_VALUES: Partial<CreateFoxKeyPayload> = {
  encryption: FoxKeyEncryption.RC4,
};

const VALIDATION_RULES_BEFORE_SUBMIT: Partial<Record<CreateFoxKeyFormField, Rule[]>> = {
  groupId: [],
  targetFrequencyId: [],
  keyId: [{ required: true, message: "Key ID є обов'язковим" }],
  encryption: [{ required: true, message: 'Оберіть алгоритм шифрування' }],
  body: [getFoxKeyBodyValidationRule],
};

const VALIDATION_RULES_AFTER_SUBMIT: Partial<Record<CreateFoxKeyFormField, Rule[]>> = {
  ...VALIDATION_RULES_BEFORE_SUBMIT,
  groupId: [getFoxKeyGroupIdFrequencyRule],
  targetFrequencyId: [getFoxKeyGroupIdFrequencyRule],
};

const FOX_KEY_ENCRYPTION_OPTIONS = [
  { value: FoxKeyEncryption.RC4, label: 'RC4 40 bit' },
  { value: FoxKeyEncryption.Hytera40, label: 'Hytera40 40 bit' },
  { value: FoxKeyEncryption.AES256, label: 'AES 256 bit' },
];

interface Params {
  isOpen?: boolean;
  onCloseModal: () => void;
}

const useCreateFoxKeyModal = ({ isOpen, onCloseModal }: Params) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryClient = useQueryClient();
  const [form] = Form.useForm<CreateFoxKeyPayload>();
  const { foxFrequencies, isAllFoxFrequenciesFetching } = useGetAllDigitalFoxFrequencies({
    sourceId,
    options: { enabled: isOpen },
  });
  const foxKeyTargetOptions = useMemo(() => {
    return foxFrequencies.map(({ id, value }) => ({
      label: value,
      value: id,
    }));
  }, [foxFrequencies]);

  const handleCloseModal = () => {
    form.resetFields();
    setIsFormSubmitted(false);
    onCloseModal();
  };

  const { isFoxKeyCreating, createFoxKey } = useCreateFoxKey({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [FOX_KEYS_QUERY_KEY, sourceId],
      });
      handleCloseModal();
    },
    onError: (error) => {
      validationService.validateForm({ form, error, validations });
    },
  });

  const handleFinish = (formValues: CreateFoxKeyPayload) => {
    createFoxKey(getNormalizedFormValues(formValues));
  };

  const handleFoxKeyGroupIdChange = async () => {
    await validationService.validateFormFields<CreateFoxKeyPayload>(form, { nameList: ['targetFrequencyId'] });
  };

  const handleFoxKeyTargetFrequencyIdChange = async () => {
    await validationService.validateFormFields<CreateFoxKeyPayload>(form, { nameList: ['groupId'] });
  };

  const handleFoxKeyEncryptionChange = async () => {
    await validationService.validateFormFields<CreateFoxKeyPayload>(form, { nameList: ['body'] });
  };

  const handleModalOkClick = async () => {
    if (isFormSubmitted) {
      form.submit();
    } else {
      setIsFormSubmitted(true);
    }
  };

  useEffect(() => {
    if (!isFormSubmitted) return;

    form.submit();
  }, [isFormSubmitted]);

  return {
    form,
    isFoxKeyCreating,
    initialValues: FORM_INITIAL_VALUES,
    validationRules: isFormSubmitted ? VALIDATION_RULES_AFTER_SUBMIT : VALIDATION_RULES_BEFORE_SUBMIT,
    foxKeyEncryptionOptions: FOX_KEY_ENCRYPTION_OPTIONS,
    foxKeyTargetOptions,
    isAllFoxFrequenciesFetching,
    handleModalOkClick,
    handleFinish,
    handleNormalizeItem: normalizeFormItem,
    handleCloseModal,
    handleFoxKeyEncryptionChange,
    handleFoxKeyGroupIdChange,
    handleFoxKeyTargetFrequencyIdChange,
  };
};

export default useCreateFoxKeyModal;
