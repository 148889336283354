import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getTranscriptsList } from '@/entities/transcript/api';
import { TRANSCRIPTS_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import useSyncTranscriptsDictionary from '../useSyncTranscriptsDictionary';
import useRefetchInterval from './useRefetchInterval';
import useUpdateExtraParams from './useUpdateExtraParams';

type Params = {
  queryParams: string;
  shouldAutoRefetch?: boolean;
};

const useGetTranscripts = ({ queryParams, shouldAutoRefetch }: Params) => {
  const { refetchInterval, setRefetchInterval } = useRefetchInterval(shouldAutoRefetch);
  const [foffset, setFoffset] = useState<string | null>(null);
  const [clusterExcluded, setClusterExcluded] = useState<string[]>();
  const [lastItemTimestamp, setLastItemTimestamp] = useState<number | null>();
  const [score, setScore] = useState<number | null>();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => {
    return [TRANSCRIPTS_QUERY_KEY, queryParams];
  }, [queryParams]);

  const remove = useCallback(() => {
    queryClient.removeQueries({ queryKey });
  }, [queryClient, queryKey]);

  const paginationParams = useMemo(() => {
    return {
      foffset,
      clusterExcluded,
      lastItemTimestamp: typeof lastItemTimestamp === 'number' ? String(lastItemTimestamp) : lastItemTimestamp,
      score: typeof score === 'number' ? String(score) : score,
    };
  }, [foffset, clusterExcluded, lastItemTimestamp, score]);

  const { data, list, getListRef, isLoading, isFetching, isRefetching, refetch, forceFetchNextPage } =
    useInfiniteListQuery({
      // We intentionally do not observe paginationParams parameter in queryKey to avoid redundant requests
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey,
      queryFn: ({ pageParam }) => {
        return getTranscriptsList({
          currentPage: pageParam as number,
          queryParams: queryParamsService.extendByExtra({
            pageParam: pageParam as number,
            queryParams,
            extra: paginationParams,
          }),
        });
      },
      initialPageParam: 1,
      refetchInterval,
      staleTime: Infinity,
    });

  useUpdateExtraParams({ data, setFoffset, setClusterExcluded, setLastItemTimestamp, setScore });
  useSyncTranscriptsDictionary({ memoTranscripts: list });

  return {
    data,
    transcripts: list,
    getTranscriptsRef: getListRef,
    isLoading,
    isFetching,
    isRefetching,
    refetch,
    remove,
    forceFetchNextPage,
    refetchInterval,
    setRefetchInterval,
  };
};

export default useGetTranscripts;
