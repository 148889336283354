import { QueryKey, useQueryClient } from '@tanstack/react-query';
import useDeleteInfinitePages from './useDeleteInfinitePages';

const useClearInfiniteListAndRefetch = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const handleDeleteInfinitePages = useDeleteInfinitePages(queryKey);

  const handleClearInfiniteListAndRefetch = async () => {
    try {
      handleDeleteInfinitePages(0);
      await queryClient.refetchQueries({ queryKey });
    } catch (e) {
      console.error('useClearInfiniteListAndRefetch error', e);
    }
  };

  return handleClearInfiniteListAndRefetch;
};

export default useClearInfiniteListAndRefetch;
