import { ReactElement } from 'react';
import { Row, Typography } from 'antd';
import type { File } from '@/entities/source/@fox/types';
import { Highlighter, Tooltip } from '@/shared/components';
import type { GetListRefFn } from '@/shared/types';
import styles from './styles.module.css';

interface Props {
  fileName: File['fileName'];
  listItemRef: ReturnType<GetListRefFn>;
  additionalElement: ReactElement;
  search: string;
}

const RecordName = ({ fileName, listItemRef, additionalElement, search }: Props) => {
  return (
    <Row
      ref={listItemRef}
      align="middle"
      justify="start"
    >
      {additionalElement}
      <Tooltip
        withNativeHtmlWrapper
        title={fileName}
        nativeHtmlWrapperClassName={styles.fileNameText}
      >
        <Typography.Text>
          <Highlighter
            text={fileName}
            searchTokens={[search]}
          />
        </Typography.Text>
      </Tooltip>
    </Row>
  );
};

export default RecordName;
