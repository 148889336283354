import { Dispatch, SetStateAction } from 'react';
import { FoxFrequencyRangeLimitation } from '@/entities/source/@fox/constants';
import { useIsMobile } from '@/shared/hooks';
import { SelectedSlider } from '../constants';
import { getFixedRange, handleDraggingLeftSlider, handleDraggingRightSlider, getMiddleRange } from '../utils';
import useFocusInfo from './useFocusInfo';

const useFrequencyRange = (steps: number[], range: number[], setRange: Dispatch<SetStateAction<number[]>>) => {
  const isMobile = useIsMobile();
  const { getFocusInfo, setFocusInfo } = useFocusInfo(range);

  const handleAfterChange = () => setFocusInfo(null);

  const handleSliderChange = (value: number[]) => {
    const info = getFocusInfo(value);

    if (!info) return;

    const { slider, initValue } = info;

    setRange((prevRange) => {
      if (slider === SelectedSlider.Middle) {
        return getMiddleRange(prevRange, value, FoxFrequencyRangeLimitation.Max);
      }

      if (slider === SelectedSlider.Left) {
        const leftValue = initValue[1] === value[0] ? value[1] : value[0];

        return handleDraggingLeftSlider(steps, prevRange, leftValue);
      }

      if (slider === SelectedSlider.Right) {
        const rightValue = initValue[0] === value[1] ? value[0] : value[1];

        return handleDraggingRightSlider(steps, prevRange, rightValue);
      }

      return prevRange;
    });
  };

  const handleNumberChange = (index: SelectedSlider) => (newValue: number | null) => {
    if (!newValue) return;

    const step = range[1] - range[0];

    if (index === SelectedSlider.Left) {
      const rightValue = newValue + step;

      if (rightValue > FoxFrequencyRangeLimitation.Max) {
        setRange(getFixedRange([FoxFrequencyRangeLimitation.Max - step, FoxFrequencyRangeLimitation.Max]));
      } else {
        setRange(getFixedRange([newValue, newValue + step]));
      }
    }

    if (index === SelectedSlider.Right) {
      const leftValue = newValue - step;

      if (leftValue < FoxFrequencyRangeLimitation.Min) {
        setRange(getFixedRange([FoxFrequencyRangeLimitation.Min, FoxFrequencyRangeLimitation.Min + step]));
      } else {
        setRange(getFixedRange([newValue - step, newValue]));
      }
    }
  };

  return {
    isMobile,
    handleAfterChange,
    handleSliderChange,
    handleNumberChange,
  };
};

export default useFrequencyRange;
