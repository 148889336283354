import { useMutation, MutateOptions } from '@tanstack/react-query';
import {
  activateFoxFrequency as activateFoxFrequencyApi,
  ActivateFoxFrequencyParams,
} from '@/entities/source/@fox/api';
import { ActivateFoxFrequency } from '@/entities/source/@fox/types';
import { ACTIVATE_FOX_FREQUENCY_MUTATION_KEY } from '@/shared/constants';

const useActivateFoxFrequency = (
  options: MutateOptions<ActivateFoxFrequency, unknown, ActivateFoxFrequencyParams> = {}
) => {
  const { mutate: activateFoxFrequency, isPending: isFoxFrequencyActivating } = useMutation({
    ...options,
    mutationKey: [ACTIVATE_FOX_FREQUENCY_MUTATION_KEY],
    mutationFn: activateFoxFrequencyApi,
  });

  return {
    activateFoxFrequency,
    isFoxFrequencyActivating,
  };
};

export default useActivateFoxFrequency;
