import { ReactNode } from 'react';

type Props = {
  times: number;
  renderCallback: (index: number) => ReactNode;
};

const RepeatComponent = ({ times, renderCallback }: Props) => {
  return <>{Array.from({ length: times }).map((_, index) => renderCallback(index))}</>;
};

export default RepeatComponent;
