export const CAPITAL_CITY_DISTRICTS: Record<string, string> = {
  чорнобиль: 'Київська',
  київ: 'Київська',
  севастополь: 'Автономна Республіка Крим',
};

export const LOCATION_TYPES = {
  capital: 'CAPITAL_CITY',
  district: 'DISTRICT',
};

export const LOCALES = {
  'location.type.district': 'район',
  'location.type.state.short': 'обл.',
};

export const LOCATIONS_PAGE_SIZE = '200';

export const LOCATION = 'Локація';
export const LOCATIONS = 'Локації';
export const LOCATION_PLACEHOLDER = 'Всі локації';
