import { RefObject } from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps, Typography } from 'antd';
import classNames from 'classnames';
import type { BaseSelectRef } from 'rc-select';
import { idEncoderService } from '@/shared/services';
import { filterSelectOption } from '@/shared/utils';
import { Option, ClearIcon } from './components';
import styles from './styles.module.css';
import { useSelect } from './useSelect';

export interface SelectProps<T, K> extends Omit<AntdSelectProps<T>, 'value' | 'onChange'> {
  inputRef?: RefObject<BaseSelectRef>;
  value?: T;
  onChange?: (value: T, option: K) => void;
  isAsync?: boolean;
  isTransparentOnDesktop?: boolean;
  stickToParentContainer?: boolean | RefObject<HTMLElement>;
  isNotFoundContentVisible?: boolean;
  optionClassName?: string;
  onOptionMouseEnter?: (value: string) => void;
  onOptionMouseLeave?: (value: string) => void;
}

export const Select = <T, K>({
  inputRef,
  mode,
  className,
  placeholder,
  optionFilterProp,
  filterOption,
  value,
  onChange,
  searchValue,
  onSearch,
  onClear,
  onBlur,
  loading,
  showSearch = true,
  allowClear = true,
  isAsync,
  isTransparentOnDesktop,
  stickToParentContainer = true,
  isNotFoundContentVisible = true,
  optionClassName,
  onOptionMouseEnter,
  onOptionMouseLeave,
  ...props
}: SelectProps<T, K>) => {
  const {
    selectRef,
    getDefaultPlaceholder,
    getPopupContainer,
    search,
    handleSearch,
    notFoundValue,
    handleClear,
    handleBlur,
    isMobile,
    size,
  } = useSelect({
    inputRef,
    mode,
    searchValue,
    onSearch,
    onClear,
    onBlur,
    loading,
    isAsync,
    isTransparentOnDesktop,
    stickToParentContainer,
  });

  const notFoundContent = (
    <Typography.Text className={styles.notFoundContent}>
      не знайдено результатів за запитом <Typography.Text strong>{`"${notFoundValue}"`}</Typography.Text>
    </Typography.Text>
  );

  return (
    <AntdSelect
      size={size}
      {...props}
      ref={selectRef}
      mode={mode}
      className={classNames(
        styles.select,
        {
          [styles.transparentSelect]: isTransparentOnDesktop && !isMobile,
        },
        className
      )}
      placeholder={placeholder ?? getDefaultPlaceholder()}
      value={value}
      onChange={onChange as AntdSelectProps<T>['onChange']}
      searchValue={search}
      onSearch={showSearch ? handleSearch : undefined}
      notFoundContent={isNotFoundContentVisible && search ? notFoundContent : <span />}
      onClear={handleClear}
      onBlur={handleBlur}
      loading={loading}
      showSearch={showSearch}
      allowClear={!loading && allowClear ? { clearIcon: <ClearIcon selectRef={selectRef} /> } : false}
      filterOption={optionFilterProp ? (filterOption ?? filterSelectOption(idEncoderService, optionFilterProp)) : false}
      getPopupContainer={getPopupContainer}
      optionRender={(optionProps) => (
        <Option
          {...optionProps}
          className={optionClassName}
          onMouseEnter={!isMobile ? onOptionMouseEnter : undefined}
          onMouseLeave={!isMobile ? onOptionMouseLeave : undefined}
        />
      )}
    />
  );
};
