import { Dispatch, SetStateAction } from 'react';
import { Form, Slider, InputNumber, SliderSingleProps, Space, Flex } from 'antd';
import { FoxFrequencyRangeLimitation } from '@/entities/source/@fox/constants';
import { SelectedSlider } from './constants';
import useFrequencyRange from './hooks/useFrequencyRange';
import styles from './styles.module.css';

const LABEL = 'Діапазон:';

const marks: SliderSingleProps['marks'] = {
  [FoxFrequencyRangeLimitation.Min]: '100 MHz',
  [FoxFrequencyRangeLimitation.Max]: '1000 MHz',
};

interface Props {
  steps: number[];
  range?: number[];
  setRange: Dispatch<SetStateAction<number[]>>;
  isDisabled?: boolean;
}

const FrequencyRange = ({
  steps,
  range = [FoxFrequencyRangeLimitation.Min, FoxFrequencyRangeLimitation.Max],
  setRange,
  isDisabled = false,
}: Props) => {
  const { isMobile, handleNumberChange, handleSliderChange, handleAfterChange } = useFrequencyRange(
    steps,
    range,
    setRange
  );

  const slider = (
    <Slider
      className={styles.slider}
      value={range}
      min={FoxFrequencyRangeLimitation.Min}
      max={FoxFrequencyRangeLimitation.Max}
      range={{ draggableTrack: true }}
      onChangeComplete={handleAfterChange}
      marks={marks}
      onChange={handleSliderChange}
      disabled={isDisabled}
      tooltip={{ placement: isMobile ? 'right' : 'top' }}
    />
  );

  const rangeInputs = (
    <Flex gap={isMobile ? 12 : 16}>
      <InputNumber
        onPressEnter={(e) => e.preventDefault()}
        className={styles.input}
        value={range[0]}
        step="0.001"
        controls={false}
        min={FoxFrequencyRangeLimitation.Min}
        max={FoxFrequencyRangeLimitation.Max}
        onChange={handleNumberChange(SelectedSlider.Left)}
        changeOnBlur
        disabled={isDisabled}
      />
      <InputNumber
        onPressEnter={(e) => e.preventDefault()}
        className={styles.input}
        value={range[1]}
        step="0.001"
        controls={false}
        min={FoxFrequencyRangeLimitation.Min}
        max={FoxFrequencyRangeLimitation.Max}
        onChange={handleNumberChange(SelectedSlider.Right)}
        changeOnBlur
        disabled={isDisabled}
      />
    </Flex>
  );

  return isMobile ? (
    <Form.Item>
      <Space>
        {LABEL}
        {rangeInputs}
      </Space>
      {slider}
    </Form.Item>
  ) : (
    <Form.Item label={LABEL}>
      <div className={styles.fields}>
        {slider}
        {rangeInputs}
      </div>
    </Form.Item>
  );
};

export default FrequencyRange;
