import { memo } from 'react';
import { AnchorGridProps } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/components/AnchorGrid/types';
import { getFrequencyPosition } from '@/entities/source/@fox/components/ScanningTab/components/Spectrogram/utils';
import styles from './styles.module.css';

interface Props extends Pick<AnchorGridProps, 'startFrequency' | 'endFrequency' | 'topPosition'> {
  lineFrequency: number;
}

const Line = memo(({ startFrequency, endFrequency, topPosition, lineFrequency }: Props) => {
  return (
    <div
      className={styles.wrapper}
      style={getFrequencyPosition({
        startFrequency,
        endFrequency,
        frequency: lineFrequency,
        top: `calc(${topPosition}% + 18px)`,
      })}
    />
  );
});

Line.displayName = 'Line';

export default Line;
