import { memo, RefObject } from 'react';
import { ROW_HEIGHT } from '@/shared/components/FilterSelect/constants';
import { LoadingWrapper } from '@/shared/components/LoadingWrapper';
import VirtualList from '@/shared/components/VirtualList';
import { FilterSelectAction, FilterSelectProps, FilterSelectValueType } from '@/shared/types';
import { SelectListItem, SelectListLoader, SelectListNotFound } from './components';
import styles from './styles.module.css';
import useSelectList from './useSelectList';

interface Props
  extends Pick<FilterSelectProps, 'options' | 'searchValue' | 'isLoading' | 'loadMore' | 'isFetchingNextPage'> {
  resizableRef: RefObject<HTMLDivElement>;
  selectedValues: FilterSelectValueType[];
  onItemClick?: (value: FilterSelectValueType, action: FilterSelectAction) => void;
}

const SelectList = memo(
  ({
    resizableRef,
    options,
    selectedValues,
    onItemClick,
    searchValue,
    isLoading = false,
    loadMore,
    isFetchingNextPage = false,
  }: Props) => {
    const { shouldRenderNotFoundContent, notFoundValue } = useSelectList({
      options,
      isLoading,
      searchValue,
    });

    if (isLoading) {
      return <SelectListLoader resizableRef={resizableRef} />;
    }

    if (shouldRenderNotFoundContent) {
      return <SelectListNotFound notFoundValue={notFoundValue} />;
    }

    return (
      <LoadingWrapper
        spinClassName={styles.spin}
        isLoading={isFetchingNextPage}
        isFixedOverlay
      >
        <VirtualList
          data={options}
          defaultRowHeight={ROW_HEIGHT}
          overscan={0}
          loadMore={loadMore}
          renderRow={(option) => (
            <SelectListItem
              option={option}
              isSelected={selectedValues.includes(option.value)}
              onClick={onItemClick}
            />
          )}
        />
      </LoadingWrapper>
    );
  }
);

SelectList.displayName = 'SelectList';

export default SelectList;
