import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { activateUnitUser as activateUnitUserApi } from '@/entities/unit/api';
import { UnitUser } from '@/entities/unit/types';
import { ResponseData } from '@/shared/api';
import { ACTIVATE_UNIT_USER_MUTATION_KEY } from '@/shared/constants';

interface Params {
  userId: number;
  options?: UseMutationOptions<ResponseData<UnitUser>, unknown, unknown>;
}

const useActivateUnitUser = ({ userId, options }: Params) => {
  const { mutate: activateUnitUser, isPending: isUnitUserActivating } = useMutation({
    ...options,
    mutationKey: [ACTIVATE_UNIT_USER_MUTATION_KEY, userId],
    mutationFn: () => {
      return activateUnitUserApi(userId);
    },
  });

  return {
    activateUnitUser,
    isUnitUserActivating,
  };
};

export default useActivateUnitUser;
