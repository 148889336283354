import { useMemo } from 'react';
import { Tab } from '@/shared/types';
import { NavigationHotkeys, TextHotkeys, MapHotkeys, AudioHotkeys } from '../components';
import { ItemKey, ItemLabel } from '../constants';

const useTabs = () => {
  const tabs: Tab[] = useMemo(
    () => [
      {
        key: ItemKey.Navigation,
        label: ItemLabel.Navigation,
        children: <NavigationHotkeys />,
      },
      {
        key: ItemKey.Text,
        label: ItemLabel.Text,
        children: <TextHotkeys />,
      },
      {
        key: ItemKey.Map,
        label: ItemLabel.Map,
        children: <MapHotkeys />,
      },
      {
        key: ItemKey.Audio,
        label: ItemLabel.Audio,
        children: <AudioHotkeys />,
      },
    ],
    []
  );

  return {
    tabs,
  };
};

export default useTabs;
