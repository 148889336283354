import { useSearchParams } from 'react-router';
import { idEncoderService } from '@/shared/services';

export const useSearchParam = <T extends string>(searchParam: string): [T, (val: T, replace?: boolean) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get(searchParam) ?? '';
  const setSearchParamValue = (value: T, replace: boolean = true) => {
    const prevSearchParams = new URLSearchParams(window.location.search);
    if (value) prevSearchParams.set(searchParam, idEncoderService.normalize(value));
    else prevSearchParams.delete(searchParam);
    setSearchParams(prevSearchParams, { replace });
  };
  return [searchParamValue as T, setSearchParamValue];
};
