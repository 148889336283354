import { Checkbox, CheckboxProps } from 'antd';
import { FilterSelectAction, FilterSelectOptionType, FilterSelectValueType } from '@/shared/types';
import styles from './styles.module.css';

type Props = {
  option: FilterSelectOptionType;
  isSelected?: boolean;
  onClick?: (value: FilterSelectValueType, action: FilterSelectAction) => void;
};

const SelectListItem = ({ option, isSelected, onClick }: Props) => {
  const handleChange: CheckboxProps['onChange'] = (e) => {
    onClick?.(option.value, e.target.checked ? 'select' : 'deselect');
  };

  return (
    <Checkbox
      className={styles.selectListItem}
      checked={isSelected}
      onChange={handleChange}
    >
      {option.label}
    </Checkbox>
  );
};

export default SelectListItem;
