import { useMutation, MutateOptions } from '@tanstack/react-query';
import { deleteFoxKey as deleteFoxKeyApi } from '@/entities/source/@fox/api';
import { DeleteFoxKeyParams } from '@/entities/source/@fox/types';
import { DELETE_FOX_KEY_MUTATION_KEY } from '@/shared/constants';

const useDeleteFoxKey = (options: MutateOptions<unknown, unknown, DeleteFoxKeyParams> = {}) => {
  const { mutate: deleteFoxKey, isPending: isFoxKeyDeleting } = useMutation({
    ...options,
    mutationKey: [DELETE_FOX_KEY_MUTATION_KEY],
    mutationFn: deleteFoxKeyApi,
  });

  return {
    deleteFoxKey,
    isFoxKeyDeleting,
  };
};

export default useDeleteFoxKey;
