import { useMutation, MutateOptions } from '@tanstack/react-query';
import { updateUnitArea as updateUnitAreaApi } from '@/entities/unit/api';
import { UpdateUnitAreaParams } from '@/entities/unit/api/types';
import { UPDATE_UNIT_AREA_MUTATION_KEY } from '@/shared/constants';

const useUpdateUnitArea = (options: MutateOptions<{}, unknown, UpdateUnitAreaParams> = {}) => {
  const { mutate: updateUnitArea, isPending: isUnitAreaUpdating } = useMutation({
    ...options,
    mutationKey: [UPDATE_UNIT_AREA_MUTATION_KEY],
    mutationFn: updateUnitAreaApi,
  });

  return { updateUnitArea, isUnitAreaUpdating };
};

export default useUpdateUnitArea;
