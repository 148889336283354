import { PropsWithChildren } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import { MapContextProvider } from '@/entities/map/context';
import { MapLayout } from './components';
import styles from './styles.module.css';
import { MapContainerProps } from './types';
import useMapContainer from './useMapContainer';

const MapContainer = ({
  tileSource,
  mapOptions,
  mapFooter,
  isMapAvailable,
  children,
  ...restProps
}: PropsWithChildren<MapContainerProps>) => {
  const { providerWrapperRef, mapContainerRefCallback, map, isMapLoaded, isHotkeysEnabled, isFullScreenEnabled } =
    useMapContainer({
      tileSource,
      mapOptions,
      isMapAvailable,
    });

  return (
    <MapContextProvider
      map={map}
      isHotkeysEnabled={isHotkeysEnabled}
    >
      <Flex
        ref={providerWrapperRef}
        className={classNames(styles.providerWrapper, {
          [styles.inFullscreen]: isFullScreenEnabled,
        })}
        vertical
      >
        <MapLayout
          mapContainerRefCallback={mapContainerRefCallback}
          map={map}
          tileSource={tileSource}
          {...restProps}
        >
          {isMapLoaded && children}
        </MapLayout>
        {mapFooter}
      </Flex>
    </MapContextProvider>
  );
};

export default MapContainer;
