import { useMemo } from 'react';
import { getDefaultDateRange } from '@/entities/transcript/utils';
import { queryParamsService } from '@/shared/services';
import useMapHistorySettings from './useMapHistorySettings';

type Params = {
  radioNetworkId?: string;
  dateRange: string;
};

const useParsedDateRange = ({ radioNetworkId, dateRange }: Params): [string, string] => {
  const history = useMapHistorySettings();

  const [customDateFrom, customDateTo] = useMemo(() => {
    if (history?.isEnabled && history?.dateRange) {
      return history.dateRange.map(String);
    }

    return queryParamsService.convertQueryParamToArray(dateRange);
  }, [history, dateRange]);

  return useMemo(() => {
    const [defaultDateFrom, defaultDateTo] = getDefaultDateRange();
    const dateFrom = !radioNetworkId && !customDateFrom ? String(defaultDateFrom.valueOf()) : customDateFrom;
    const dateTo = !radioNetworkId && !customDateTo ? String(defaultDateTo.valueOf()) : customDateTo;

    return [dateFrom, dateTo];
  }, [radioNetworkId, customDateFrom, customDateTo]);
};

export default useParsedDateRange;
