import { UpdateUnitAreaParams } from '@/entities/unit/api/types';
import { request, ResponseData, PaginatedWithFoffset, RequestQueryParams } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import {
  CreateUnitPayload,
  GetUnitSourcesQueryParams,
  Unit,
  UnitDetails,
  UnitSource,
  UnitUser,
  UpdateUnitArea,
  UpdateUnitParams,
  UpdateUnitUserParams,
} from '../types';
import {
  createUnitSchema,
  getUnitsSchema,
  getUnitUsersSchema,
  getFreeUsersSchema,
  activateUnitUserSchema,
  deactivateUnitUserSchema,
  updateUnitUserSchema,
  getUnitDetailsSchema,
  updateUnitAreaSchema,
  getUnitSourcesSchema,
  updateUnitSchema,
} from './validationSchemas';

export const getUnits = ({ queryParams }: { queryParams: RequestQueryParams }) =>
  request<ResponseData<Unit[]>>('/units', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getUnitsSchema,
    params: {
      ...queryParams,
    },
  });

export const getUnitDetails = (unitId: string) =>
  request<ResponseData<UnitDetails>>(`/units/${unitId}`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getUnitDetailsSchema,
  });

export const createUnit = (payload: CreateUnitPayload) =>
  request<ResponseData<Unit>>('/units', {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: createUnitSchema,
  });

export const updateUnit = ({ unitId, payload }: UpdateUnitParams) =>
  request<ResponseData<Unit>>(`/units/${unitId}`, {
    endpointPrefix: V3_PREFIX,
    method: 'PATCH',
    body: JSON.stringify(payload),
    validationSchema: updateUnitSchema,
  });

export const getFreeUsers = ({ queryParams }: { queryParams: RequestQueryParams }) =>
  request<PaginatedWithFoffset<UnitUser>>('/users', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFreeUsersSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });

export const getUnitUsers = ({ unitId, queryParams }: { unitId: string; queryParams: RequestQueryParams }) =>
  request<PaginatedWithFoffset<UnitUser>>(`/units/${unitId}/users`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getUnitUsersSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });

export const activateUnitUser = (userId: number) =>
  request<ResponseData<UnitUser>>(`/users/${userId}/activate`, {
    endpointPrefix: V3_PREFIX,
    method: 'POST',
    validationSchema: activateUnitUserSchema,
  });

export const deactivateUnitUser = (userId: number) =>
  request<ResponseData<UnitUser>>(`/users/${userId}/deactivate`, {
    endpointPrefix: V3_PREFIX,
    method: 'POST',
    validationSchema: deactivateUnitUserSchema,
  });

export const updateUnitUser = ({ userId, payload }: UpdateUnitUserParams) =>
  request<ResponseData<UnitUser>>(`/users/${userId}`, {
    endpointPrefix: V3_PREFIX,
    method: 'PATCH',
    body: JSON.stringify(payload),
    validationSchema: updateUnitUserSchema,
  });

export const getUnitSources = ({ queryParams }: { queryParams: GetUnitSourcesQueryParams }) =>
  request<PaginatedWithFoffset<UnitSource>>('/unit-sources', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getUnitSourcesSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });

export const updateUnitArea = ({ unitId, payload }: UpdateUnitAreaParams) =>
  request<UpdateUnitArea>(`/units/${unitId}/area`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: updateUnitAreaSchema,
  });
