import dayjs, { Dayjs } from 'dayjs';

const getDefaultDateRange = (currentDate: Dayjs = dayjs()): [Dayjs, Dayjs] => {
  const startDate = currentDate.add(-30, 'd').startOf('day');
  const endDate = currentDate.endOf('day');

  return [startDate, endDate];
};

export default getDefaultDateRange;
