import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { ErrorBoundary, withProfiler } from '@sentry/react';
import { ErrorCrash } from '@/shared/components';
import { NotificationContextProvider } from '@/shared/context';
import { sentryService } from '@/shared/services';
import './App.css';
import { configureDayjs, configureMockServer, configureViewport } from './configs';
import { QueryClientProvider } from './queryClient';
import { router } from './router';
import { store } from './store';
import SupportWrapper from './supportWrapper';
import { ThemeConfigProvider } from './theme';

sentryService.configure();
configureViewport();
configureDayjs();
configureMockServer();

/**
 * Root Application Component
 * Renders all context providers and application Routes inside
 */
const App = () => {
  return (
    <ErrorBoundary
      fallback={ErrorCrash}
      beforeCapture={sentryService.beforeCapture('app level')}
    >
      <ReduxProvider store={store}>
        <ThemeConfigProvider>
          <NotificationContextProvider>
            <QueryClientProvider>
              <SupportWrapper>
                <DndProvider
                  backend={TouchBackend}
                  options={{ enableMouseEvents: true, enableTouchEvents: false }}
                >
                  <RouterProvider router={router} />
                </DndProvider>
              </SupportWrapper>
            </QueryClientProvider>
          </NotificationContextProvider>
        </ThemeConfigProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

export default withProfiler(App);
