import { useEffect } from 'react';
import { hotkeysHelperService } from '@/shared/services';
import { useIsMobile } from './mobile';

type Params = {
  hotkey: string;
  withShiftKey?: boolean;
  withCtrlKey?: boolean;
  withAltKey?: boolean;
  memoHotkeyUp?: VoidFunction;
  memoIsSilent?: boolean;
};

const useHotkeyListener = ({
  hotkey,
  withShiftKey = false,
  withCtrlKey = false,
  withAltKey = false,
  memoHotkeyUp,
  memoIsSilent = false,
}: Params) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (memoIsSilent) return;

    const handleKeyUp = (e: KeyboardEvent) => {
      // cancel event when text field is in focus
      if (hotkeysHelperService.getIsInputOrTextarea(e)) return;
      // cancel event when modal or drawer is open
      if (hotkeysHelperService.getIsOverlayComponentOpen({ isMobile })) return;

      if (withShiftKey && !e.shiftKey) return;
      if (withCtrlKey && !(e.ctrlKey || e.metaKey)) return;
      if (withAltKey && !e.altKey) return;

      if (e.key === hotkey || e.code === `Key${hotkey}`) {
        memoHotkeyUp?.();
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [isMobile, memoHotkeyUp, memoIsSilent]);
};

export default useHotkeyListener;
