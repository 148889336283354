import { useAppSelector } from '@/shared/hooks';
import { selectNavigationDrawer } from '@/shared/slices';
import { useTabs } from './hooks';

const useHotkeysDrawer = () => {
  const navigationDrawer = useAppSelector(selectNavigationDrawer);
  const { tabs } = useTabs();

  return {
    navigationDrawer,
    tabs,
  };
};

export default useHotkeysDrawer;
