import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { KeysTab, RecordsTab, ScanningTab } from '@/entities/source/@fox';
import { useNavigateToSource, useSource } from '@/entities/source/hooks';
import { RouteLink } from '@/shared/components';
import { SourceTab } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import { isEnumValue } from '@/shared/utils';
import { SettingsTab } from '../components';
import useTabRouteGuard from './useTabRouteGuard';

const TABS = [
  {
    key: SourceTab.Scanning,
    label: 'Сканування',
    children: <ScanningTab />,
  },
  {
    key: SourceTab.Records,
    label: 'Записи',
    children: <RecordsTab />,
  },
  {
    key: SourceTab.Keys,
    label: 'Ключі',
    children: <KeysTab />,
  },
  {
    key: SourceTab.Settings,
    label: 'Налаштування',
    children: <SettingsTab />,
  },
];

const useTabs = () => {
  const { sourceId = '', activeTab } = useParams<SourceRouteParams>();
  const navigate = useNavigateToSource();
  const { search } = useLocation();
  const source = useSource();
  const isFoxSource = source?.type === 'fox';

  const defaultActiveTab = useMemo(() => {
    return isFoxSource ? SourceTab.Scanning : SourceTab.Settings;
  }, [isFoxSource]);

  const filteredTabs = useMemo(() => {
    return isFoxSource ? TABS : TABS.filter(({ key }) => key === SourceTab.Settings);
  }, [isFoxSource]);

  const handleTabChange = (tab: string) => {
    if (isEnumValue(tab, SourceTab)) {
      navigate({
        routeParams: {
          sourceId,
          activeTab: tab,
        },
      });
    }
  };

  const tabLinks = filteredTabs.map((item) => ({
    ...item,
    label: (
      <RouteLink
        href={`${routingService.getSourceUrl({ sourceId, activeTab: item.key })}${search}`}
        onClick={() => handleTabChange(item.key)}
      >
        {item.label}
      </RouteLink>
    ),
  }));

  useTabRouteGuard({
    navigateToTab: handleTabChange,
    defaultActiveTab,
    isFoxSource,
  });

  return {
    tabs: tabLinks,
    activeTab: activeTab || defaultActiveTab,
  };
};

export default useTabs;
