import { useMutation, useQueryClient } from '@tanstack/react-query';
import { activateSubscription } from '@/entities/subscription';
import { SUBSCRIPTION_DELETE_MUTATION_KEY, SUBSCRIPTIONS_QUERY_KEY } from '@/shared/constants';

const useActivateSubscription = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending: isActivating } = useMutation({
    mutationKey: [SUBSCRIPTION_DELETE_MUTATION_KEY],
    mutationFn: activateSubscription,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUBSCRIPTIONS_QUERY_KEY],
      }),
  });
  return { activateSubscription: mutate, isActivating };
};

export default useActivateSubscription;
