interface Params {
  startFrequency: number;
  roundTo: number;
}

const getRoundedStartFrequency = ({ startFrequency, roundTo }: Params): number => {
  const EPSILON = 1e-12;
  const remainder = startFrequency % roundTo;
  const isExactMultiple = Math.abs(remainder) < EPSILON || Math.abs(remainder - roundTo) < EPSILON;

  if (isExactMultiple) {
    return parseFloat(startFrequency.toFixed(6));
  }

  const nextMultipleCount = Math.floor(startFrequency / roundTo) + 1;
  const result = nextMultipleCount * roundTo;
  return parseFloat(result.toFixed(6));
};

export default getRoundedStartFrequency;
