import { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useAuthRouteGuard } from './hooks';

type Props = {
  isAuthRequired?: boolean;
  otherwise: string;
  children: ReactElement;
};

export const AuthRouteGuard = ({ isAuthRequired = true, otherwise, children }: Props) => {
  const shouldRedirect = useAuthRouteGuard(isAuthRequired);

  if (shouldRedirect) {
    return (
      <Navigate
        to={otherwise}
        replace
      />
    );
  }

  return children;
};
