import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useUnitRouteInfo, useTableItemModalManager } from '@/entities/unit/hooks';
import { UnitSource } from '@/entities/unit/types';
import { UNIT_SOURCES_QUERY_KEY } from '@/shared/constants';
import { useRenderPropsContext } from '@/shared/context';
import { useScrollUpToReFetch } from '@/shared/hooks';
import { UnitRenderProps } from '@/shared/types';
import useGetUnitSources from './useGetUnitSources';
import useUnitSourcesTableColumns from './useUnitSourcesTableColumns';

const useUnitSourcesTab = () => {
  const { routeUnitId } = useUnitRouteInfo();
  const queryClient = useQueryClient();
  const { itemId, handleOpenModal, handleCloseModal } = useTableItemModalManager<UnitSource['id']>();
  const unitQueryKey = [UNIT_SOURCES_QUERY_KEY, routeUnitId];
  const { renderEditSourceModal } = useRenderPropsContext<UnitRenderProps>();

  const { unitSourcesList, getUnitSourcesRef, isUnitSourcesFetching } = useGetUnitSources({ unitId: routeUnitId });

  const source = useMemo(() => unitSourcesList.find(({ id }) => id === itemId), [unitSourcesList, itemId]);

  const columns = useUnitSourcesTableColumns({ getUnitSourcesRef, handleOpenModal });

  const { handleScrollUpToReFetch } = useScrollUpToReFetch(unitQueryKey);

  const handleUnitInvalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: unitQueryKey,
    });
  };

  return {
    sourcesList: unitSourcesList,
    isLoading: isUnitSourcesFetching,
    columns,
    handleScrollUpToReFetch,
    memoSource: source,
    handleCloseModal,
    handleUnitInvalidate,
    renderEditSourceModal,
  };
};

export default useUnitSourcesTab;
