import { useMemo, memo, useRef, useState } from 'react';
import { Dropdown, ButtonProps } from 'antd';
import { ItemType, MenuDividerType, MenuItemType } from 'antd/es/menu/interface';
import { MoreOutlined } from '@ant-design/icons';
import { useIsMobile } from '@/shared/hooks';
import { MobileOverlay } from '../mobile/MobileOverlay';
import { Button } from '../ui/Button';
import styles from './styles.module.css';

interface ActionsDropdownProps extends Pick<ButtonProps, 'icon'> {
  selectedKeys?: string[];
  overlayClassName?: string;
  additionalItems?: MenuItemType[];
  className?: string;
  wrapperClassName?: string;
  attachDesktopDropdownToButton?: boolean;
  isLoading?: boolean;
}

interface ItemsMandatory {
  items: MenuItemType[];
  dangerItems?: MenuItemType[];
}

interface DangerItemsMandatory {
  dangerItems: MenuItemType[];
  items?: MenuItemType[];
}

type Props = ActionsDropdownProps & (ItemsMandatory | DangerItemsMandatory | (DangerItemsMandatory & ItemsMandatory));

const ActionsDropdown = memo(
  ({
    items: mainItems = [],
    additionalItems,
    dangerItems,
    className,
    wrapperClassName,
    attachDesktopDropdownToButton,
    isLoading,
    icon,
    selectedKeys,
    overlayClassName,
  }: Props) => {
    const isMobile = useIsMobile();
    const [isOpen, setIsOpen] = useState(false);
    const wrapRef = useRef<HTMLDivElement>(null);
    const items = useMemo(() => {
      const result: ItemType[] = [...mainItems];
      const dividerItem: MenuDividerType = { type: 'divider' };

      if (additionalItems?.length) {
        if (mainItems.length) {
          result.push(dividerItem);
        }
        result.push(...additionalItems);
      }

      if (dangerItems?.length) {
        if (mainItems.length || additionalItems?.length) {
          result.push(dividerItem);
        }
        result.push(
          ...dangerItems.map((item) => ({
            ...item,
            danger: true,
          }))
        );
      }

      return result;
    }, [mainItems, additionalItems, dangerItems]);

    const getPopupContainer =
      attachDesktopDropdownToButton && !isMobile ? () => wrapRef.current as HTMLElement : undefined;

    return items.length ? (
      <div
        ref={wrapRef}
        className={wrapperClassName}
      >
        <MobileOverlay isOpen={isOpen} />
        <Dropdown
          menu={{ items, ...{ 'data-testid': 'actions-dropdown-list' }, selectedKeys }}
          placement="bottomRight"
          trigger={['click']}
          className={className}
          getPopupContainer={getPopupContainer}
          rootClassName={styles.dropdown}
          onOpenChange={(open) => setIsOpen(open)}
          destroyPopupOnHide
          overlayClassName={overlayClassName}
        >
          <Button
            loading={isLoading}
            className={styles.button}
            icon={icon ?? <MoreOutlined />}
            onClick={(e) => e.stopPropagation()}
            data-testid="actions-dropdown-button"
          />
        </Dropdown>
      </div>
    ) : null;
  }
);

ActionsDropdown.displayName = 'ActionsDropdown';

export default ActionsDropdown;
