import { PropsWithChildren } from 'react';
import { Tooltip } from '@/shared/components';
import { useSourceDatesTooltip } from './hooks';
import styles from './styles.module.css';
import { SourceDatesTooltipProps } from './types';

type Props = PropsWithChildren<SourceDatesTooltipProps>;

const SourceDatesTooltip = ({
  lastActiveAt,
  createdAt,
  statusChangedAt,
  isActivated,
  children,
  ...tooltipProps
}: Props) => {
  const { title, overlayStyle } = useSourceDatesTooltip({ lastActiveAt, createdAt, statusChangedAt, isActivated });

  return (
    <Tooltip
      {...tooltipProps}
      title={title}
      overlayStyle={overlayStyle}
      withNativeHtmlWrapper
      className={styles.tooltip}
    >
      {children}
    </Tooltip>
  );
};

export default SourceDatesTooltip;
