import { Flex, Typography } from 'antd';
import styles from './styles.module.css';

const NOT_FOUND_RESULTS = 'Не знайдено результатів за запитом';

type Props = {
  notFoundValue: string;
};

const SelectListNotFound = ({ notFoundValue }: Props) => {
  return (
    <Flex className={styles.notFoundContentWrapper}>
      <Flex className={styles.notFoundContent}>
        {NOT_FOUND_RESULTS}
        <Typography.Text strong>{`"${notFoundValue}"`}</Typography.Text>
      </Flex>
    </Flex>
  );
};

export default SelectListNotFound;
