import { useSearchParams } from 'react-router';
import { FOX_RECORDS_FILTERS_STORAGE_KEY, FOX_SCANNING_FILTERS_STORAGE_KEY } from '@/entities/source/@fox/constants';
import { FoxRecordsFilterLocalStorageParams, FoxScanningFilterLocalStorageParams } from '@/entities/source/@fox/types';
import { getSourceSearchParams } from '@/entities/source/utils';
import { FoxRecordsQueryParam, FoxScanningQueryParam } from '@/shared/constants';
import { useIsMobile, useLocalStorageForLimitedDays, useNavigate } from '@/shared/hooks';
import { routingService } from '@/shared/services';
import { QueryParamsOptions, SourceRouteParams } from '@/shared/types';

interface Params {
  routeParams: SourceRouteParams;
  options?: {
    withMobileView?: boolean;
    withAdditionalQueryParamsOnly?: boolean;
    additionalQueryParams?: [string, string][];
  } & Pick<QueryParamsOptions, 'withAllQueryParams'>;
}

const useNavigateToSource = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { getStorageValue: getScanningFiltersStorageValue } = useLocalStorageForLimitedDays<
    Record<string, FoxScanningFilterLocalStorageParams>
  >(FOX_SCANNING_FILTERS_STORAGE_KEY);
  const { getStorageValue: getRecordsFiltersStorageValue } = useLocalStorageForLimitedDays<
    Record<string, FoxRecordsFilterLocalStorageParams>
  >(FOX_RECORDS_FILTERS_STORAGE_KEY);
  const [, setSearchParams] = useSearchParams();

  return ({ routeParams, options = {} }: Params) => {
    const { withMobileView, withAdditionalQueryParamsOnly, additionalQueryParams } = options;
    const scanningFilterList = getScanningFiltersStorageValue() || {};
    const recordsFilterList = getRecordsFiltersStorageValue() || {};
    const { sourceId, activeTab } = routeParams;

    navigate({
      pathname: routingService.getSourceUrl(routeParams),
    });

    setSearchParams(
      (searchParams) =>
        getSourceSearchParams({
          sourceId,
          activeTab,
          searchParams,
          withMobileView: isMobile && !!withMobileView,
          scanningQueryParams: FoxScanningQueryParam,
          recordsQueryParams: FoxRecordsQueryParam,
          scanningFilterList: withAdditionalQueryParamsOnly ? undefined : scanningFilterList,
          recordsFilterList: withAdditionalQueryParamsOnly ? undefined : recordsFilterList,
          additionalQueryParams,
        }),
      { replace: true }
    );
  };
};

export default useNavigateToSource;
