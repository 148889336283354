import { memo } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { RadioNetworkName } from '@/entities/network';
import { FrequencyIcon } from '@/shared/assets';
import { RouteLink } from '@/shared/components';
import { RadioNetworkType } from '@/shared/constants';
import { timeService } from '@/shared/services';
import { RadioNetwork } from '@/shared/types';
import { getListItemClassNames } from '@/shared/utils';
import styles from './styles.module.css';
import useStaticListItem from './useStaticListItem';
import { isWithinLast24Hours } from './utils';

const NEW_NETWORK = 'Нова мережа';
const NEW_FREQUENCY = 'Нова частота';

interface Props {
  item: RadioNetwork;
  selectedRadioNetworkId?: string;
  isDragging?: boolean;
}

const StaticListItem = memo(({ item, selectedRadioNetworkId, isDragging }: Props) => {
  const { isMobile, search, radioNetworkLinkHref, handleItemClick } = useStaticListItem(item);

  return (
    <RouteLink
      className="list-item"
      href={radioNetworkLinkHref}
      onClick={handleItemClick}
      isBlock
    >
      <Row
        className={classNames(
          styles.row,
          getListItemClassNames({
            selected: selectedRadioNetworkId === item.id,
            isDragging,
          }),
          styles.item
        )}
        justify="space-between"
        align="middle"
        wrap={false}
      >
        <Col flex="auto">
          <Space>
            <div className={styles.iconWrap}>
              {item.id &&
                (item.type === RadioNetworkType.NETWORK ? (
                  <DeploymentUnitOutlined className={styles.networkIcon} />
                ) : (
                  <FrequencyIcon className={styles.frequencyIcon} />
                ))}
              {isWithinLast24Hours(item.firstActiveAt) && (
                <div className={styles.newRadioNetworkIcon}>
                  {item.type === RadioNetworkType.NETWORK ? NEW_NETWORK : NEW_FREQUENCY}
                </div>
              )}
            </div>
            <Typography.Paragraph
              ellipsis={{ rows: 2, tooltip: !isMobile && item.value }}
              strong
              className={styles.valueText}
              data-testid="name"
            >
              <RadioNetworkName
                radioNetworkName={item.value}
                searchTokens={[search]}
              />
            </Typography.Paragraph>
          </Space>
        </Col>
        <Col
          flex="none"
          className={styles.lastActiveAtWrapper}
        >
          {item.lastActiveAt ? (
            <Typography.Text
              type="secondary"
              data-testid="last-active-at"
            >
              {timeService.getFormattedDate(item.lastActiveAt)}
            </Typography.Text>
          ) : null}
        </Col>
      </Row>
    </RouteLink>
  );
});

StaticListItem.displayName = 'StaticListItem';

export default StaticListItem;
