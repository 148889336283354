import { ThemeConfig } from 'antd';
import './common.css';

/**
 * This config just sets the primary color for the theme palette and allows to override other styles for Ant Design components.
 * All color variables are generated internally by Ant Design based on the primary color.
 * They are located in the token and with our changes are also available as CSS variables.
 *
 * To quick find all colors that used in the project open the browser inspector and look at the :root CSS selector
 *
 * Ant Design Token variables description
 * https://ant.design/docs/react/customize-theme#seedtoken
 * https://ant.design/docs/react/customize-theme#maptoken
 * https://ant.design/docs/react/customize-theme#aliastoken
 */
export const config: ThemeConfig = {
  token: {
    borderRadius: 2,
    fontSizeLG: 14,
    fontFamily:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Alert: {
      fontSizeLG: 16,
    },
    Tag: {
      fontSize: 12,
    },
    Notification: {
      zIndexPopupBase: 99999,
    },
    Skeleton: {
      borderRadiusSM: 4,
    },
  },
};

export * from './constants';
