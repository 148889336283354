import { PropsWithChildren } from 'react';
import { Tooltip as AntTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import classNames from 'classnames';
import { useIsTouchDevice } from '@/shared/hooks';
import styles from './styles.module.css';

export type TooltipProps = AntdTooltipProps & {
  isDesktopOnly?: boolean;
  isOneLine?: boolean;
  withNativeHtmlWrapper?: boolean; // issue fix https://ant.design/components/tooltip#why-does-the-warning-finddomnode-is-deprecated-sometimes-appear-in-strict-mode
  nativeHtmlWrapperClassName?: string;
};

export const Tooltip = ({
  children,
  overlayClassName,
  isDesktopOnly,
  isOneLine,
  mouseEnterDelay = 1,
  withNativeHtmlWrapper = false,
  nativeHtmlWrapperClassName,
  ...props
}: PropsWithChildren<TooltipProps>) => {
  const isTouchDevice = useIsTouchDevice();

  return isDesktopOnly && isTouchDevice ? (
    children
  ) : (
    <AntTooltip
      {...props}
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={classNames(overlayClassName, {
        [styles.oneLine]: isOneLine,
      })}
    >
      {withNativeHtmlWrapper ? <div className={nativeHtmlWrapperClassName}>{children}</div> : children}
    </AntTooltip>
  );
};
