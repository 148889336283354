import { ZOOM_STEP } from '../constants';
import { SpectrogramProps, ZoomData } from '../types';
import getRoundedFloat from './getRoundedFloat';
import getZoomData from './getZoomData';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  zoomData: ZoomData;
}

const getOneStepOutData = ({ zoomData, startFrequency, endFrequency }: Params) => {
  const currentStartFrequency = zoomData?.startZoomFrequency ?? startFrequency;
  const currentEndFrequency = zoomData?.endZoomFrequency ?? endFrequency;
  const oneStepFrequencies = ((endFrequency - startFrequency) / 100) * ZOOM_STEP;
  let zoomOneStepOutStartFrequency = getRoundedFloat(currentStartFrequency - oneStepFrequencies);
  let zoomOneStepOutEndFrequency = getRoundedFloat(currentEndFrequency + oneStepFrequencies);
  const isRestrictedZoomLevel =
    zoomOneStepOutStartFrequency <= startFrequency && zoomOneStepOutEndFrequency >= endFrequency;

  if (zoomOneStepOutEndFrequency > endFrequency) {
    zoomOneStepOutStartFrequency = getRoundedFloat(
      endFrequency - (zoomOneStepOutEndFrequency - zoomOneStepOutStartFrequency)
    );
    zoomOneStepOutEndFrequency = endFrequency;
  } else if (zoomOneStepOutStartFrequency < startFrequency) {
    zoomOneStepOutEndFrequency = getRoundedFloat(
      startFrequency + (zoomOneStepOutEndFrequency - zoomOneStepOutStartFrequency)
    );
    zoomOneStepOutStartFrequency = startFrequency;
  }

  const frequenciesSelectedBoundaries: ZoomData = getZoomData({
    startFrequency,
    endFrequency,
    frequenciesSelectedBoundaries: {
      startSelectedFrequency: zoomOneStepOutStartFrequency,
      endSelectedFrequency: zoomOneStepOutEndFrequency,
    },
  });

  const defaultSelectedBoundaries: ZoomData = null;

  return isRestrictedZoomLevel ? defaultSelectedBoundaries : frequenciesSelectedBoundaries;
};

export default getOneStepOutData;
