import { SourceType } from '@/shared/types';

export const ADD_SOURCE = 'Додати джерело';

export const SOURCE_NAME_LABEL: Partial<Record<SourceType, string>> = {
  fox: 'Назва пристрою',
  bilka: 'Назва команди в Стрибо',
  whatsapp: 'Назва групи в Стрибо',
  signal: 'Назва групи в Стрибо',
};

export const SOURCE_NAME_PLACEHOLDER: Partial<Record<SourceType, string>> = {
  fox: 'Назва пристрою',
  bilka: 'Назва команди',
  whatsapp: 'Назва групи',
  signal: 'Назва групи',
};

export const SOURCE_NAME_DESCRIPTION: Partial<Record<SourceType, string>> = {
  fox: 'Назва посту або позивний місця де встановлено пристрій',
  bilka: 'Назва команди яка буде відображатись на перехопленнях',
  whatsapp: 'Назва групи яка буде відображатись на перехопленнях',
  signal: 'Назва групи яка буде відображатись на перехопленнях',
};

export const SOURCE_IDENTIFY_NUMBER_LABEL: Partial<Record<SourceType, string>> = {
  fox: 'Серійний номер пристрою',
  bilka: 'Команда Commintify (External ID)',
  whatsapp: 'Група WhatsApp (External ID)',
  signal: 'Група Signal (External ID)',
};

export const SOURCE_IDENTIFY_NUMBER_PLACEHOLDER: Partial<Record<SourceType, string>> = {
  fox: 'хххххххх-хххх-хххх-хххх-хххххххххххх',
};
