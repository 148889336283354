import { useEffect, useState } from 'react';
import { FilterSelectProps } from '@/shared/types';

interface Params extends Pick<FilterSelectProps, 'options' | 'isLoading' | 'searchValue'> {}

const useSelectList = ({ options, isLoading, searchValue }: Params) => {
  const isEmpty = options.length === 0;
  const shouldRenderNotFoundContent = isEmpty && searchValue;
  const [notFoundValue, setNotFoundValue] = useState('');

  useEffect(() => {
    if (!isLoading && searchValue) {
      setNotFoundValue(searchValue);
    }
  }, [isLoading, searchValue]);

  return {
    shouldRenderNotFoundContent,
    notFoundValue,
  };
};

export default useSelectList;
