import { ReactNode, PropsWithChildren } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';
import { HeaderTitle } from '@/shared/components/HeaderTitle';
import styles from './styles.module.css';

type Props = {
  className?: string;
  sidebar?: ReactNode;
  actions?: ReactNode;
  testId?: string;
};

export const Header = ({ className, sidebar, actions, testId, children }: PropsWithChildren<Props>) => {
  return (
    <Row
      className={classNames(styles.headerWrapper, className)}
      data-testid={testId}
    >
      <HeaderTitle>{children}</HeaderTitle>
      {(sidebar || actions) && (
        <div className={styles.headerSidebar}>
          {sidebar}
          {actions}
        </div>
      )}
    </Row>
  );
};
