import { Line } from './components';
import { useAnchorGrid } from './hooks';
import { AnchorGridProps as Props } from './types';

const AnchorGrid = ({ startFrequency, endFrequency, gap, topPosition, canBeVisible, wrapperWidth }: Props) => {
  const { lineFrequenciesList, shouldBeVisible } = useAnchorGrid({ startFrequency, endFrequency, gap, wrapperWidth });

  if (!canBeVisible || !shouldBeVisible) return null;

  return lineFrequenciesList.map((lineFrequency) => (
    <Line
      key={lineFrequency}
      startFrequency={startFrequency}
      endFrequency={endFrequency}
      topPosition={topPosition}
      lineFrequency={lineFrequency}
    />
  ));
};

export default AnchorGrid;
