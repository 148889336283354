import { MutateOptions, useMutation } from '@tanstack/react-query';
import { markVoiceSkip as markVoiceSkipApi } from '@/entities/source/@fox/api';
import { MarkVoiceSkip } from '@/entities/source/@fox/types';
import { MARK_VOICE_SKIP_MUTATION_KEY } from '@/shared/constants';

const useMarkVoiceSkip = (options: MutateOptions<MarkVoiceSkip, unknown, string> = {}) => {
  const { mutate: markVoiceSkip, isPending: isMarkVoiceSkipLoading } = useMutation({
    ...options,
    mutationKey: [MARK_VOICE_SKIP_MUTATION_KEY],
    mutationFn: markVoiceSkipApi,
  });

  return {
    markVoiceSkip,
    isMarkVoiceSkipLoading,
  };
};

export default useMarkVoiceSkip;
