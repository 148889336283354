import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { LocalStorageService } from '@/shared/services/LocalStorageService';
import { ErrorTrackingService, StorageService } from '@/shared/types';

export class SentryService implements ErrorTrackingService {
  private developEnvs = ['alpha', 'bravo', 'whiskey'];

  private fetchDynamicallyImportedModuleErrorMessage = 'Failed to fetch dynamically imported module';

  static readonly lastReloadTimeStorageKey = 'last-reload-time';

  constructor(private storageService: StorageService<string>) {}

  configure() {
    const domain = window.location.hostname.split('.')[0];

    if (process.env.NODE_ENV === 'production' && domain !== 'localhost') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: this.developEnvs.includes(domain) ? domain : 'production',
        integrations: [
          Sentry.extraErrorDataIntegration(),
          Sentry.dedupeIntegration(),
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        // parameter configures amount of transaction are made, it's recommended to start from lower value and increase it due to needs
        tracesSampleRate: 0.2,
        autoSessionTracking: false,
        beforeSend: (event, { originalException: err }) => {
          // Sentry will ignore these errors
          if (axios.isAxiosError(err)) return null;
          return event;
        },
      });
    }
  }

  setUser(user: Record<string, unknown>) {
    Sentry.setUser(user);
  }

  captureException(exception: unknown) {
    Sentry.captureException(exception);
  }

  captureExpandedException(params: Parameters<ErrorTrackingService['captureExpandedException']>[0]) {
    const { exception, extraData, fingerprint } = params;

    Sentry.withScope((scope) => {
      if (extraData) {
        scope.setExtra(extraData.name, extraData.value);
      }
      if (fingerprint) {
        scope.setFingerprint(fingerprint);
      }
      Sentry.captureException(exception);
    });
  }

  beforeCapture(errorBoundaryLevel: string) {
    return (scope: Sentry.Scope, error: unknown) => {
      this.handlePreloadError(error);
      scope.setTag('errorBoundaryLevel', errorBoundaryLevel);
      scope.setExtra('errorBoundaryLevel', errorBoundaryLevel);
    };
  }

  // https://github.com/vitejs/vite/issues/11804
  // 'Failed to fetch dynamically imported module' error occurs for various reasons.
  // In our case, it rarely occurs when a user navigates from one page to another.
  // Solution - reload page before user see Error Page and before error sent to Sentry.
  // If a problem occurs again in a short time, then this step will be dropped, and user will see Error Page, and Sentry will receive error.
  private handlePreloadError(error: unknown) {
    const isPreloadError =
      typeof error === 'object' &&
      error !== null &&
      'message' in error &&
      typeof error.message === 'string' &&
      error?.message.includes(this.fetchDynamicallyImportedModuleErrorMessage);

    if (!isPreloadError) return;

    const lastReloadTime = this.storageService.getStorageValue();
    const now = Date.now();

    if (!lastReloadTime || now - Number(lastReloadTime) > 5_000) {
      this.storageService.setStorageValue(now.toString());
      window.location.reload();
    }
  }
}

export const sentryService = new SentryService(new LocalStorageService(SentryService.lastReloadTimeStorageKey));
